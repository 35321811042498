export const orderLabels = {
  "dashboard-section-heading": "Order Online",
  "select-store-for-menu-modal-message": "Please select a location to view the menu",
  "select-order-time-for-menu-modal-message": "Please select a valid order time to view the menu",
  "closest-store": "Closest location to you",
  "select-store": "Select a location",
  "find-store": "Find a location",
  pickup: "Pickup",
  delivery: "Delivery",
  "dine-in": "Dine-in",
  "select-time": "Select time",
  "no-future-dates": "Location is unavailable",
  "pickup-and-delivery-times-disclaimer": "Pick-up and delivery times are approximate.",
  featured: "Featured",
  "select-order-time-for-featured-modal-message":
    "Please select a valid order time to view the featured items",
  "whats-new": "What's New",
  "selected-store": "Selected location",
  today: "Today",
  "in-minutes": "In [number] minutes",
  "map-overlay-pickup-heading": "Let's find a location for Pickup",
  "map-overlay-delivery-heading": "What is the Location for Delivery?",
  "use-your-location": "Use your location",
  "or-enter-an-address": "Or Enter an Address",
  "maps-searchbox-placeholder": "Enter city, zip/postal code...",
  "previously-found-stores": "Previous:",
  "order-here": "Order Here",
  "order-ahead": "Order Ahead",
  "list-view": "List View",
  "map-view": "Map View",
  "more-store-info-button": "Info",
  "close-more-store-info-button": "Back",
  menu: "Menu",
  favorites: "Favourites",
  "no-favorites-message": "You have not saved any favourites.",
  "remove-favorite-confirmation": "Are you sure you want to remove this item from favourites?",
  "view-favorite-item": "View Item",
  calories: "Cal",
  "select-options": "Select Options",
  "modify-this-item": "Modify this item",
  "add-extras": "Add extras",
  "special-instructions": "Special Instructions",
  "add-to-order": "Add [item-quantity] to Order",
  "add-another": "Add Another",
  "menu-disclaimer": "",
  "menu-item-disclaimer": "",
  added: "Added",
  "save-changes": "Save Changes",
  "button-save-changes-success": "Saved",
  "recent-orders": "Recent Orders",
  reorder: "Reorder",
  "view-one-item": "View 1 item",
  "view-multiple-items": "View [number] items",
  "view-cart": "View Cart",
  "back-to-menu": "Back to Menu",
  "review-order": "Review Order",
  "your-order-details": "Your Order Details",
  "items-in-the-cart": "item(s) in cart",
  "discount-and-coupon-button": "Discount/Coupon",
  "discount-code": "Discount Code",
  "check-discount": "Check Discount",
  "coupon-code": "Coupon Code",
  "check-coupon": "Check Coupon",
  "discount-remove-confirmation": "Are you sure you want to remove this discount code?",
  "use-rewards": "Use Rewards",
  discount: "Discount",
  coupon: "Coupon",
  "apply-discount-coupon": "Apply",
  "invalid-discount-coupon-error": "Discount code is not valid.",
  "only-one-discount-reward-error":
    "Only one discount/reward can be applied to your order. Please remove the currently applied discount if you would like to add a different one.",
  tax: "Tax",
  "add-tip": "Add Tip",
  "no-tip": "No Tip",
  tip: "Tip",
  "custom-amount": "Custom Amount",
  modify: "Modify",
  "remove-all-items": "Remove All",
  "add-more-items": "Add More",
  "empty-cart-message": "Your shopping cart is empty.",
  "begin-checkout": "Begin Checkout",
  "customer-info-form-heading": "Let's confirm your order contact",
  "order-contact-confirm": "Next",
  "order-contact-confirming": "Next...",
  "pickup-information": "Pickup Information",
  "review-order-disclaimer": "",
  "pickup-information-subheading": "",
  payment: "Payment",
  "use-another-credit-card": "Use another credit card",
  "saved-credit-card-heading": "Saved credit card",
  "return-to-saved-credit-card": "Return to saved credit card",
  "replacing-saved-credit-card-confirmation":
    "You already have a saved credit card. Are you sure you want to remove [card-number]?",
  "card-balance": "Card Balance",
  "apply-gift-card-to-order-button": "Apply",
  "registered-gift-card": "Registered Gift Card",
  "back-to-registered-gift-card": "Back to registered gift card",
  "apply-rewards-to-order-button": "Apply",
  "use-another-gift-card": "Use another gift card",
  "gift-card-number": "Gift Card Number",
  "pin-number": "PIN Number",
  "no-active-gift-cards-message": "You currently do not have any active cards.",
  "order-total": "Order Total",
  "remaining-balance-on-gift-card": "Remaining on Card",
  "pay-remainder-with-credit-card": "Pay remainder with a credit card",
  "amount-due": "Amount Due",
  "card-has-already-been-applied-message": "This card has already been applied",
  "pay-on-pickup": "Pay upon pickup",
  "pay-on-delivery": "Pay upon delivery",
  "submit-order-button": "Complete Order",
  "submit-order-button-processing": "Completing Order...",
  "payment-disclaimer": "",
  "order-too-close-to-closing-time-error":
    "We regret to say that we cannot accept your order at this time. Online orders are not accepted very close to or past closing time.",
  "select-order-time-passed-message":
    "Your selected order time has passed. The next available order time would be",
  "confirm-updated-order-time-message": "Would you like to proceed with the updated order time?",
  "order-confirmation": "Order Confirmation",
  "order-thank-you-message": "Thank you for your order",
  "your-order-number-is": "Your order number is",
  "we-sent-you-confirmation-email": "We have sent you a confirmation email.",
  "order-confirmation-disclaimer": "",
  "item-for-pickup-at": "[quantity] item for [order-type] at",
  "items-for-pickup-at": "[quantity] items for [order-type] at",
  "item-for-delivery-or-dinein-from": "[quantity] item for [order-type] from",
  "items-for-delivery-or-dinein-from": "[quantity] items for [order-type] from",
  "item-for-dine-in-at": "[quantity] item for [order-type] at",
  "items-for-dine-in-at": "[quantity] items for [order-type] at",
  "order-type-change-confirmation": "Are you sure you want to change your order to [order-type]?",
  "order-store-change-confirmation":
    "Are you sure you want to change the location to [order-store]?",
  "your-current-order-will-be-cleared": "Your current order will be cleared.",
  "cart-item-removal-confirmation": "Are you sure you want to remove [item-name] item from cart?",
  "cart-item-removal-discount-also-removed-message":
    "Removing this item will also remove the discount code associated with it",
  "order-type-order-date-at-order-time": "[order-type] [order-date] at [order-time]",
  "delivery-shipping-form-submit-button": "Confirm",
  "delivery-shipping-form-submit-button-processing": "Confirming...",
  "no-pickup-stores-within-range-message": "No pickup locations within [stores-range]km from you.",
  "distance-to-store": "Distance [distance-from-user]km",
  "store-open-status": "Open",
  "store-closed-status": "Closed",
  "store-open-until-closing-time": "Closes at [closing-time]",
  "no-store-menu-message": "No menu found for this location",
  "select-up-to-n-items": "Select up to [max-quantity] item(s) (Optional)",
  "select-n-items": "Select [min-quantity] items",
  "select-n-item": "Select [min-quantity] item",
  "select-between-n-and-n-items": "Select between [min-quantity] and [max-quantity] items",
  "calories-disclaimer":
    "Adults and youth (ages 13 and older) need an average of 2,000 calories a day, and children (ages 4 to 12) need an average of 1,500 cal a day. However, individual needs vary.",
  "button-add-to-combo-group-choice": "Add to choice [item-group-id]",
  "button-add-to-combo-group-choice-success": "Added",
  "button-save-combo-choices": "Save Changes",
  "button-save-combo-choices-success": "Saved",
  "combo-choice": "Choice",
  "combo-summary": "Summary",
  "combo-choose-item": "Choose an item from options below",
  "add-gift-card": "Add gift card",
  "add-another-gift-card": "Add another gift card",
  "complete-order-pay-on-pickup-blurb":
    "Complete your order below and pay when you pick up in location.",
  "complete-order-pay-on-delivery-blurb":
    "Complete your order below and pay when your order gets delivered.",
  "bring-coupon-notice": "Be sure to bring your [balance] coupon!",
  "complete-order-see-you-soon": "See you soon!",
  "recent-order-item-modifier-heading": "Modified",
  "recent-order-item-addon-heading": "Added",
  "recent-order-unavailable-item-notification": "One ore more items are no longer available",
  "recent-order-price-change-notification":
    "* Subtotal from previous order may differ as prices are subject to change",
  "card-autoreload-success-message":
    "You have successfully set auto reload for card [card-number] for the amount of [amount]",
  "card-autoreload-when-balance-reaches": "when balance reaches",
  "card-autoreload-weekly-frequency": "weekly",
  "card-autoreload-monthly-frequency": "monthly",
  "add-money-add-credit-card": "Add Credit Card",
  "pay-in-store-card-expiry": "Expires on",
  "qr-code": "QR Code",
  "pay-in-store-how-to-heading": "Ready to setup in-location express checkout?",
  "pay-in-store-how-to-blurb-1":
    "Add a card to your account to be able to take advantage of express in-location checkout.",
  "pay-in-store-how-to-blurb-2":
    "You can add multiple cards to your account, check balance and add money or set automatic reload.",
  "pay-in-store-add-new-card": "Add a New Card",
  "complete-order-pay-with-paypal":
    "After clicking “Complete order”, you will be redirected to PayPal to complete your order securely.",
  "cart-removing-items-with-out-of-stock-addons-message": "Removed items with out of stock addons.",
  "cart-removing-item-with-out-of-stock-addons-message":
    "Removed [item-name] with out of stock addons.",
  "item-detail-addons-out-of-stock-error": "[number] required addons are now out of stock",
  "item-detail-addon-out-of-stock-error": "Required addon is now out of stock",
  "dashboard-primary-order-button": "Order Now",
  "dashboard-disclaimer": "",
  "dine-in-qr-scanner-instructions":
    "Scan the QR code by making sure it fits into the square below OR simply enter the table number.",
  "complete-order-pay-later-dine-in-blurb":
    "Complete your order below and pay at the end of your dine-in experience.",
  "pay-later": "Pay Later",
  "show-menu-button": "Show Menu",
  "show-menu-button-processing": "Getting Menu...",
  "table-number-field-label": "Table Number",
  "invalid-qr-code": "Invalid QR Code",
  "camera-access-not-supported":
    "This browser does not support camera access. It is best to use Safari for this feature.",
  "invalid-coupon-number": "Invalid Coupon Number",
  "back-to-combo-item-name": "Back to [combo-item-name]",
  "confirm-modified-combo-changes-button": "Confirm Changes",
  "no-delivery-stores-within-range-message":
    "No delivery locations within [stores-range]km from you.",
  "order-before-store-opening-time-error":
    "We regret to say that we cannot accept your order at this time, we are currently closed.",
  "confirm-summary-details": "Confirm Summary Details",
  "coupon-remove-confirmation": "Are you sure you want to remove this coupon?",
  "applied-discount-code": "Applied Discount/Promo Code",
  "applied-coupon-code": "Applied Coupon Code",
  asap: "ASAP",
  "minimum-delivery-amount-message":
    "A minimum order amount of [delivery-minimum-amount] is required for delivery",
  quantity: "Quantity",
  "check-balance": "Check balance",
  "out-of-stock": "Out of stock",
  "remove-item-from-favorites": "Remove [item-name] from favourites",
  "add-item-to-favorites": "Add [item-name] to favourites",
  "go-to-item-name": "Go to [item-name] item details",
  "go-to-whats-new-image-link": "Go to [image-link]",
  "remove-item-from-cart": "Remove [item-name] from cart",
  "modify-item-in-cart": "Modify [item-name]",
  "decrease-quantity": "Decrease quantity",
  "increase-quantity": "Increase quantity",
  "remove-tip": "Remove tip",
  "show-category-subcategories": "Show [category-name] subcategories",
  "show-subcategory-items": "Show [subcategory-name] items",
  "close-item-details": "Close [item-name] details",
  "back-to-item": "Back to [item-name] details",
  "open-options-group-options": "Open [option-group-name] options",
  "select-payment-type": "Select [payment-type] as payment",
  "remove-applied-giftcard": "Remove applied gift card",
  edit: "Edit",
  "delivering-to-address": "Delivering to",
  "edit-delivery-address": "Edit delivery address",
  "order-time-is-blocked":
    "Your requested time is no longer available. Please select a new time for your order",
  "ordering-from": "Ordering from",
  "select-another-store-prompt": "Select another location to view the menu",
  "select-a-store-prompt": "Select a location to view the menu",
  "discount-disclaimer": "",
  "coupon-disclaimer": "",
  "complete-your-order-below": "Complete your order below and pay when you pick up in location.",
  "invalid-reward": "Invalid Reward",
  "price-based-on-selection": "Price based on selection",
  "total-combo-price-disclaimer": "Total combo price is calculated in the shopping cart",
  "order-type": "Order type",
  "number-of-required-choices": "[number-of-choices] Required Selection(s)",
  "minutes-short-hand": "min",
  "guest-attempt-favorite-item": "Please sign in to add items to your favourites",
  "favorite-add-to-order": "Add to order",
  "read-more": "Read More",
  "read-less": "Read Less",
  "ios-camera-disclaimer":
    'To check out the menu, either:[line-break]Select "Use My Location" below, or[line-break]Scan a QR code using your device\'s camera.',
  "lets-find-store-for-order-type": "Let's find a location for [order-type]",
  "lets-find-store-blurb":
    "Enter your address, allow app to use your location or choose the closest location from the list below",
  "unit-can-be-added": "Unit# can be added at checkout",
  "use-my-location": "Use my location",
  "select-store-all": "All",
  "select-store-nearby": "Nearby",
  "select-store-favorites": "Favourites",
  "select-store-hours-info": "Hours & Info",
  "select-store-get-direction": "Get Directions",
  "select-store-select-store": "Select Location",
  "guest-attempt-favorite-location": "Please sign in to add locations to your favourites",
  "no-favorite-locations-blurb":
    "Tap/click the heart icon to save a location to your favourites for easy access",
  "no-nearby-locations-blurb":
    "In order to search for nearby locations, enter your address or tap 'Use my location'",
  "time-selection-panel-header": "Select [order-type] date and time",
  "no-delivery-stores-within-range": "No delivery locations within range.",
  "order-type-selection-blurb": "How would you like to order",
  "type-in-address": "Street Address and City",
  "location-not-in-delivery-range": "This location is not within the delivery range",
  "no-nearby-locations-blurb-delivery":
    "In order to search for nearby locations, enter your delivery address or tap 'Use My Location'",
  "price-change-error-message":
    "Please note the item(s) price(s) in your cart have been updated to reflect the currently available menu",
  "items-removed-from-menu-error-message":
    "One or more items is no longer available to order for the selected date/time.",
  "dine-in-use-location-instructions":
    "Scan QR code or allow the app to find your location to proceed",
  "dine-in-not-in-location-error": "You must be in the restaurant to be able to order dine in.",
  "dine-in-use-location-greetings":
    "Welcome to [merchant-name]. Enter your table number below to see the menu",
  "dine-in-scan-qr-code": "Scan QR Code",
  "dinein-information-special-instructions": "Dinein Information",
  "invalid-item-quantity-error": "There is no remaining stock of this item",
  "item-not-available": "This item is currently unavailable",
  "app-rating-header": "How was your app experience today?",
  "app-rating-thumbs-up": "Thumbs up",
  "app-rating-thumbs-down": "Thumbs down",
  "merchant-email-subject": "Customer Feedback for [merchant-name] CWS5",
  "merchant-email-body-template-line-1":
    "We appreciate you reaching out, please let us know how we can improve for next time. If you encountered a technical issue, please add in any details pertaining the problem and what steps you took leading up to encountering the issue below.",
  "merchant-email-do-not-remove": "***Please do not remove***",
  "merchant-email-username": "Username",
  "merchant-email-guest-user": "Guest User",
  "merchant-email-device-type": "Device Type",
  "merchant-email-os-version": "OS Version",
  "merchant-email-description-of-issue": "Description of issue",
  "merchant-email-steps-taken": "Steps Taken Prior to Encountering the Issue",
  "merchant-email-information-used-to-help":
    "This information is used to help identify and resolve technical issues.",
  "merchant-email-enter-details": "<enter_details_here>",
  "cart-out-of-stock-item-error-message":
    "Unfortunately [item-name] is no longer available. It has been removed from your cart.",
  "pickup-information-special-instruction-placeholder": "",
  "submit-order": "Submit Order",
  "order-and-pay": "Order & Pay",
  checkout: "Checkout",
  "select-payment": "Select Payment",
  "ready-to-pay": "Ready to pay?",
  "view-bill": "View Bill",
  "ready-to-pay-blurb": "Review, split and pay your bill here",
  bill: "Bill",
  "confirmation-email": "Confirmation Email",
  "confirmation-email-blurb": "Would you like a copy of your receipt emailed to you?",
  table: "table",
  "ordered-together": "Ordered Together",
  seat: "Seat [seat-number]",
  "submitting-order": "Submitting Order...",
  "payment-confirmation": "Payment Confirmation",
  "thank-you-for-payment": "Thank you for your payment",
  "email-receipt-blurb": "We have send you a confirmation email to [email]",
  "payment-confirmation-blurb": "You're all set! We hope to see you again soon.",
  "submit-payment-button": "Complete Payment",
  "submit-payment-button-processing": "Completing Payment",
  "review-items": "Review Items",
  "items-not-ready-for-billing":
    "Hang tight! We're preparing your order and you'll be able to pay shortly.",
  "bill-page-no-order-placed-yet": "No order has been submitted yet for this table.",
  refresh: "Refresh",
  "bill-api-error": "An error has occurred. Please contact your server.",
  "back-to-home": "Back to home",
  "view-details": "View Details",
  "hide-details": "Hide Details",
  "our-picks-for-you": "Our Picks For You",
  customize: "Customize",
  "ordered-from": "Ordered From",
  "delivered-to": "Delivered To",
  "points-earned": "Points Earned",
  "points-redeemed": "Points Redeemed",
  "charged-to": "Charged To",
  "active-orders": "Active Orders",
  "past-orders": "Past Orders",
  "no-orders-yet": "No Orders Yet",
  "view-menu": "View Menu",
  "browse-menu": "Browse menu and start order",
  "confirm-reorder-details": "To reorder, let's confirm your order details!",
  "active-orders-loading": "Hang tight! We are retrieving your active orders for you",
  "past-orders-loading": "Hang tight! We are retrieving your past orders for you",
  "addon-no-thank-you-option": "No, thank you. Skip these choices",
  "select-n-items-or-more": "Select [n-items] or more",
  "date-and-time": "Date & Time",
  "combo-summary-header": "Combo Summary",
  "edit-combo-item-arial": "Edit combo item [item-name]",
  "setting-favorite-error": "Please satisfy the choices below and then add to your favorites",
  oops: "Oops!",
  "reorder-limited-stock-items":
    "Looks like the following item(s) could not be added to your cart due to limited stock",
  "reorder-items-no-longer-available":
    "Looks like the following item(s) could not be added to your cart since they are no longer available on the menu",
  "reorder-items-out-of-stock-no-longer-available":
    "Looks like the following item(s) could not be added to your cart due to limited stock or they are no longer available",
  "add-new-item": "Add New Item",
  customization: "Customization",
  "remove-all-items-confirmation-modal-message": "Are you sure you want to remove all [item-name]?",
  "choice-n-of-total": "Choice [current-choice] of [total-choices]",
  "hang-tight-processing-payment": "Hang tight! We're processing your payment",
  "hang-tight-reviewing-order": "Hang tight! We're processing your order now",
  "contacting-bank-to-confirm-payment": "We are contacting your bank to confirm your payment",
  "almost-done-passing-order-to-store": "Almost done! We are sending your order to the store",
  "almost-done": "Almost done!",
  "do-not-click-back-or-refresh":
    "Please do not click back, refresh or leave this page as this could interrupt the process and/or duplicate your charges.",
  connecting: "Connecting...",
  "complete-order-pay-with-google-pay":
    "After clicking on the Checkout button below, you will be redirected to Google Pay to complete your order securely.",
  "complete-order-pay-with-apple-pay":
    "After clicking “Check out with [apple-pay-icon]”, you will be redirected to Apple Pay to complete your order securely.",
  "order-processed-using-digital-payment": "and it was successfully processed using",
  "google-pay": "Google Pay",
  "apple-pay": "Apple Pay",
  "preparing-bill-for-verification": "Preparing your bill for verification...",
  "retrieving-bill-details": "Retrieving bill details...",
  "processing-your-bill": "Processing your bill...",
  "verifying-bill-details": "Verifying your bill details...",
  "double-checking-your-bill": "Double checking your bill...",
  "confirming-bill-details": "Confirming your bill details, payment processing underway!",
  "processing-your-payment": "Processing your payment...",
  "all-done": "All Done!",
  "delivery-address": "Delivery Address",
  "delivery-addresses": "Delivery Addresses",
  "confirm-address": "Confirm Address",
  "use-my-location-for-delivery": "Use my location for the delivery address",
  "use-my-location-for-pickup": "Use my location to find nearby stores",
  "confirm-your-pickup-location": "Confirm Your Pickup Location",
  "modify-location": "Modify Location",
  "find-address": "Find Address",
  "primary-delivery-address-message":
    "Would you like to save the delivery address below to your account?",
  "set-as-primary-delivery-address": "Set as primary delivery address",
  "recommended-address-changes":
    "Please review the recommended change(s) and choose the correct option.",
  "save-address": "Save Address",
  "not-now": "Not Now",
  "your-addresses": "Your Addresses",
  "no-saved-delivery-address": "You currently have no saved delivery address yet.",
  "you-entered": "You have entered:",
  recommended: "Recommended:",
  "unable-to-verify-address": "We could not verify your address!",
  "try-different-address": "Try a different address",
  "store-filter-list": "List",
  "store-filter-map": "Map",
  "verification-issues": "Verification Issues",
  "street-number-not-found": "Street number not found",
  "use-entered-address": "Use Entered",
  "make-changes-to-address": "Make Changes",
  "street-number-missing": "Street number is missing",
  "confirm-seating": "Confirm Seating",
  "edit-seating": "Edit Seating",
  "order-pickup": "Order Pickup",
  "stadium-service-not-available": "This service is not available for your section.",
  "in-seat-service": "In-Seat Service",
  "in-seat-service-time-title": "Select in-seat service time",
  "stadium-pickup-time-title": "Select pickup time",
  "limit-per-order": "Limit [qty-limit] per order",
  "limit-reached": "Maximum limit per order reached",
  "limit-reached-past-order":
    "These items cannot be added to your cart. One or more items will exceed their maximum limit per order",
  "meal-voucher": "Meal Voucher",
  "address-already-exists": "Customer address already exists",
  "select-dates": "Select Dates",
  "select-date-range": "Select Date Range",
  "load-more": "Load More",
  "select-one-bill": "Select All (Pay as one bill)",
  "open-calendar": "Open Calendar",
  "select-pickup-location": "Select pickup location",
  "select-pickup-time": "Select pickup time",
  "set-store-location-favorite": "Set this store location as favorite",
  "remove-store-location-favorite": "Remove this store location as favorite",
  "edit-order-time": "Edit order time details",
  "edit-order-type": "Edit order type",
  "back-to-payment-method-select": "Go back to payment method selection",
  "pay-with-google-pay": "Pay with Google pay",
  "pay-with-apple-pay": "Pay with Apple pay",
  "expand-section": "Expand section for",
  "collapse-section": "Collapse section for",
  "remove-discount": "Remove disocunt/coupon",
  "item-quantity": "Item quantity",
  "add-to-apple-wallet": "Add to Apple Wallet",
  "add-to-google-pay": "Add to Google Pay",
  "expand-order-details": "Expand order details",
  "collapse-order-details": "Collapse order details",
  "remove-promo": "Remove promo",
  "store-hours": "Store hours",
  "edit-ordering-locaiton": "Edit ordering from location",
  "missing-customer-id-error":
    "To secure the use of this saved credit card, please validate your sign in information",
  "missing-customer-id-error-gc":
    "To secure the use of this saved gift card, please validate your sign in information",
  "sorry-it-is-closing-time": "Sorry, It's Closing Time!",
  "store-closed-message":
    "We're unable to process your order right now because it is close to or past our closing time.",
  "pick-new-time": "Pick a new time",
  "view-full-menu": "View Full Menu",
  "recent-order-guest-attempt": "Please sign in to view your recent orders",
  "invalid-direct-url-error": "[order-type] is not currently available at this location.",
  "guest-attempt-favorite-item-dashboard": "Please sign in to view your favorites",
  "order-types": "Order Types",
};
