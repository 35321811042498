/*global google*/

import React, { useContext, useState, useEffect, useRef } from "react";
import localforage from "localforage";
import { useHistory } from "react-router";
import { useLocation, Link } from "react-router-dom";
import { Tab, Tabs, makeStyles } from "@material-ui/core";
import useWindowSize from "../_common/hooks/useWindowSize";

//Contexts
import LocationsContext from "../OnlineOrdering/Locations/LocationsContext";
import OrderTypeContext from "../OnlineOrdering/OrderTypeContext";
import AppLabelsContext from "../App/AppLabelsContext";
import UserRoleContext from "../App/UserRoleContext";
import AppLanguageContext from "../App/AppLanguageContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import StoreContext from "../OnlineOrdering/StoreContext";
import OrderTimeContext from "../OnlineOrdering/OrderTimeContext";
import BrandingContext from "../App/BrandingContext";
import CartContext from "../OnlineOrdering/Cart/CartContext";
import { MenuContext } from "../OnlineOrdering/Menu/MenuContext";
import AppSettingsContext from "../App/AppSettingsContext";

//UI Component
import { OrderStoreInfoCard } from "./OrderStoreInfoCard";
import { GoogleMaps } from "../OnlineOrdering/GoogleMaps/GoogleMaps";
import { DialogModal } from "../_common/DialogModal/DialogModal";
import { OrderStoresSearchComponent } from "./OrderStoresSearchComponent";
import { LoadingSpinner } from "../_common/LoadingSpinner";

/** Assets */
import { IconEdit } from "../_common/icons";
import ListIcon from "@material-ui/icons/List";
import { LocationSearchingTwoTone, Map } from "@material-ui/icons";

// Helper functions
import { jsonCopy } from "../_common/helpers";
import {
  getOrderItemDetails,
  getUnavailableItems,
} from "../OnlineOrdering/RecentOrders/orderHistoryHelpers";
import { setStoreHoursProperties } from "../OnlineOrdering/GoogleMaps/dateHelpers";
import { updateCustomerFavorite } from "../OnlineOrdering/Favorites/favoriteHelpers";
import { getContrastColor } from "../_common/colorHelpers";
import { getStore1306Config } from "../App/apiHelpers/getStore1306Config";
import {
  getTimeAmPm,
  getTrueBusinessDate,
  newTime,
  prettifyDate,
  roundTime,
  roundToNearestQuarter,
} from "../Dashboard/DashboardOrder/dateHelpers";
import { get1300Stores } from "../OnlineOrdering/GoogleMaps/get1300Stores";
import { onAddProductToCartXtreme } from "../_common/helpers/xtremePushHelper";

import {
  getOpeningTime,
  getClosingTime,
  formatStoreTimeOverrides,
  isTimeSlotBlocked,
  getIntermittentIncrementInterval,
  getMinInterval,
  getFormattedStoreHoursWithMidnight,
  getDayOfWeekFromIndex,
  isWithinBusinessHours,
} from "../OnlineOrdering/Locations/helpers/isStoreOpenOrClosed";
import { isValidOrderTime } from "../_common/PaymentHelpers";
import {
  checkAvailableOrderItems,
  formatItems,
} from "../OnlineOrdering/Menu/helpers/orderingHelpers";
import { checkLimitedStockedItemToCart, isValidItemQuantity } from "../_common/MenuHelpers";

//CSS
import "./OrderStores.css";

export const OrderStores = (props) => {
  const { triggerMenuRef } = props;
  const STORE_RANGE = 100000; // in meters = 100km
  const history = useHistory();
  const { state } = useLocation();

  const homeLinkRef = useRef(null);
  const checkLocalForageForFavStore = useRef(true);
  const searchboxInputRef = useRef(null);
  const autocompleteRef = useRef(null);

  //Context
  const orderTypeContext = useContext(OrderTypeContext);
  const orderType = orderTypeContext.value;
  const merchantConfig = useContext(MerchantConfigContext);
  const allStoresContext = useContext(LocationsContext);
  const appLabels = useContext(AppLabelsContext);
  const appLanguage = useContext(AppLanguageContext);
  const userRoleContext = useContext(UserRoleContext);
  const branding = useContext(BrandingContext);
  const storeContext = useContext(StoreContext);
  const orderTimeContext = useContext(OrderTimeContext);
  const cartData = useContext(CartContext);
  const menuContext = useContext(MenuContext);
  const appSettings = useContext(AppSettingsContext);

  const isStadiumSchema = merchantConfig.merchant.I55 === "stadium";

  const [favoriteLocations, setFavoriteLocations] = useState(null);
  const [userDeliveryAddress, setUserDeliveryAddress] = useState("");
  const [isCalling1327, setIsCalling1327] = useState(null); //when set, the state will contain the id of the related store
  const [customerID, setCustomerID] = useState(null); //get the customer id from storage
  const [isHandlingReorder, setIsHandlingReorder] = useState(false);
  const [isInvalidOrder, setIsInvalidOrder] = useState(false);
  const [currentStoresFilter, setCurrentStoresFilter] = useState("list");
  const [currentStoresSubFilter, setCurrentStoresSubFilter] = useState(
    isStadiumSchema ? "all" : "nearby"
  );
  const [userLocation, setUserLocation] = useState({}); //state keeping track of the selected address
  const [userSelectedAddressPrediction, setUserSelectedAddressPrediction] = useState(null);
  const [isCalling1300, setIsCalling1300] = useState(false);
  const [geoLocationButtonIsActive, setGeoLocationButtonIsActive] = useState(null);
  const [storesInRange, setStoresInRange] = useState(null);
  const [isShowingCartClearPopup, setIsShowingCartClearPopup] = useState(null);
  const [modalContent, setModalContent] = useState({});
  const [noAddressEntered, setNoAddressEntered] = useState(null);
  const [deliveryStoreNotInRange, setDeliveryStoreNotInRange] = useState(null);
  const [isAddressValid, setIsAddressValid] = useState(null);
  const [isGuestFavoriteAttempt, setIsGuestFavoriteAttempt] = useState(null);
  const [storeIdToBeFaved, setStoreIdToBeFaved] = useState(null);
  const [storeOnImpression, setStoreOnImpression] = useState(null);

  const showEditButtons = !window.location.href.includes("payment");
  const loginToken = userRoleContext.loginToken;
  const isLoggedIn = userRoleContext.status === "logged-in";
  const skin = merchantConfig.skin;
  const isAddonQuantityEnabled = appSettings["enable-addon-quantity-buttons"] === "yes";
  const secondaryColor = branding["secondary-colour"];
  const deviceWidth = useWindowSize().width;

  const removeLocationFromFavorites = (store) => {
    const updatedLocations = [...favoriteLocations];
    updatedLocations.splice(updatedLocations.indexOf(store.storeId), 1);

    setIsCalling1327(store.storeId);
    updateCustomerFavorite(
      skin,
      appLanguage,
      loginToken,
      customerID,
      "update",
      [],
      "LOCATIONS",
      updatedLocations
    )
      .then((updatedFavLocations) => {
        setIsCalling1327(null);
        if (updatedFavLocations) {
          setFavoriteLocations(updatedLocations);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsCalling1327(null);
      });
  };

  const addLocationToFavorites = (store) => {
    if (!customerID || !loginToken) {
      //use is not logged-in
      setStoreIdToBeFaved(store.storeId);
      setIsGuestFavoriteAttempt(true);
      return;
    }

    setIsCalling1327(store.storeId);
    updateCustomerFavorite(skin, appLanguage, loginToken, customerID, "add", [], "LOCATIONS", [
      store.storeId,
    ])
      .then((updatedFavorites) => {
        setIsCalling1327(null);
        if (updatedFavorites) {
          if (updatedFavorites.favItemAdded) {
            setFavoriteLocations(updatedFavorites.favItems);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        setIsCalling1327(null);
      });
  };

  const updateUserLocation = (lat, lng) => {
    setUserLocation({
      lat,
      lng,
    });
  };

  const getUserGeolocation = () => {
    setGeoLocationButtonIsActive(true);

    if (navigator && navigator.geolocation) {
      setUserLocation({});
      document.activeElement.blur(); // resets document focus to remove focus from the Find My Location button

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          if (searchboxInputRef && searchboxInputRef.current) {
            searchboxInputRef.current.value = "";
          }
          updateUserLocation(latitude, longitude);
          setUserSelectedAddressPrediction("geoLocation"); //setting an arbitrary value just to findDeliveryLocation does not return out of function
          setGeoLocationButtonIsActive(false);
          setIsAddressValid(true);
        },

        function error() {
          setGeoLocationButtonIsActive(false);
          alert(appLabels["general"]["disabled-geolocation-alert"]);
          console.log(
            "GPS position feature was disabled. To enable again - click on the location icon in the top right corner of the browser within a search field, then clear these settings for future visits and reload."
          );
        },

        { enableHighAccuracy: true }
      );
    }
  };

  const updateStoresInRange = (newStoreList) => {
    setStoresInRange("idle");
    setTimeout(() => {
      setStoresInRange(newStoreList);
    }, 500);
  };

  const getStoresInRange = () => {
    let tempStoresInRange = [];

    setIsCalling1300(true);
    get1300Stores(skin, orderType, appLanguage, userLocation.lat, userLocation.lng).then(
      (api1300Stores) => {
        if (api1300Stores) {
          api1300Stores.forEach((store) => {
            const distanceFromUserLocationInMeters = store.distanceFromUser;

            store.distanceFromUserLocationInMeters = distanceFromUserLocationInMeters;
            store.distanceFromUserLocationInKilometers = (
              distanceFromUserLocationInMeters / 1000
            ).toFixed(1);

            store.distanceFromUserLocationInMiles = (
              distanceFromUserLocationInMeters * 0.000621371
            ).toFixed(1);

            store = setStoreHoursProperties(store);
            tempStoresInRange.push(store);
          });

          updateStoresInRange(tempStoresInRange);
          getClosestStoreInRange(tempStoresInRange);
          setCurrentStoresSubFilter("nearby");
        }
        setIsCalling1300(false);
      }
    );
  };

  const findDeliveryStores = async () => {
    let tempStoresInRange = [];
    setIsCalling1300(true);
    get1300Stores(
      skin,
      orderType,
      appLanguage,
      userLocation.lat,
      userLocation.lng,
      isStadiumSchema ? userDeliveryAddress : userDeliveryAddress.postal
    ).then((apiStores) => {
      if (apiStores) {
        apiStores.forEach((store) => {
          const storeCoordsObject = new google.maps.LatLng(store.latitude, store.longitude);
          const userLocationCoordsObject = new google.maps.LatLng(
            userLocation.lat,
            userLocation.lng
          );
          const distanceFromUserLocationInMeters =
            google.maps.geometry.spherical.computeDistanceBetween(
              storeCoordsObject,
              userLocationCoordsObject
            );

          store.distanceFromUserLocationInMeters = distanceFromUserLocationInMeters;
          store.distanceFromUserLocationInKilometers = (
            distanceFromUserLocationInMeters / 1000
          ).toFixed(1);

          store.distanceFromUserLocationInMiles = (
            distanceFromUserLocationInMeters * 0.000621371
          ).toFixed(1);

          store = setStoreHoursProperties(store);
          tempStoresInRange.push(store);
        });

        getClosestStoreInRange(tempStoresInRange);
        updateStoresInRange(tempStoresInRange);

        setCurrentStoresSubFilter("nearby");
        setIsCalling1300(false);
      }
    });
  };

  /** Sort the locations based on their distance (closest to furthest) */
  const getClosestStoreInRange = (tempStoresInRange) => {
    tempStoresInRange.sort(function (a, b) {
      return a.distanceFromUserLocationInMeters - b.distanceFromUserLocationInMeters;
    });
  };

  const reverseGeoCode = async (coordinates) => {
    //Reverse geocode
    const geocoder = new google.maps.Geocoder();
    const geoCodeInfo = await geocoder.geocode({ location: coordinates });
    if (geoCodeInfo) {
      if (geoCodeInfo.results[0]) {
        return geoCodeInfo.results[0];
      } else {
        window.alert("No results found");
      }
    }
  };

  const checkOutOfStockItem = (menuItem) => {
    return Number(menuItem.item_count) <= 0 || Number(menuItem.item_count) < menuItem.quantity;
  };

  const geocodePlaceId = async (placeId) => {
    const geocoder = new google.maps.Geocoder();
    const geoCodeInfo = await geocoder.geocode({ placeId: placeId });
    if (geoCodeInfo) {
      if (geoCodeInfo.results[0]) {
        return geoCodeInfo.results[0];
      } else {
        window.alert("No results found");
      }
    }
  };

  const getUserInputAddressComponent = (input) => {
    return input.address_components;
  };

  const onSearchBoxPlacesChanged = async (prediction) => {
    setIsAddressValid(null);
    setUserLocation({});
    await geocodePlaceId(prediction.place_id).then(async (places) => {
      if (places && Object.keys(places).length > 0) {
        const position = places.geometry.location;
        const lat = position.lat();
        const lng = position.lng();
        const storeCoordsObject = new google.maps.LatLng(lat, lng);
        const userInputAddressComponent = getUserInputAddressComponent(places);
        const reverseGeoCodeResponse = userInputAddressComponent
          ? userInputAddressComponent
          : await reverseGeoCode(storeCoordsObject);

        if (!userInputAddressComponent) {
          return;
        }

        if (reverseGeoCodeResponse) {
          searchboxInputRef.current.value = "";
          setUserSelectedAddressPrediction(prediction);
          updateUserLocation(lat, lng);
          setIsAddressValid(true);
        }
      }
    });
  };

  const updateStoresFilter = (filter) => {
    setCurrentStoresFilter(filter);
  };

  const updateStoresSubFilter = (filter) => {
    setCurrentStoresSubFilter(filter);
  };

  const getFavStoreDetails = (storeId) => {
    return allStoresContext.find((store) => store.storeId === storeId);
  };

  const resetMenu = () => {
    localforage.removeItem(skin + "__storedMenu");
    menuContext.updateMenu({
      ...menuContext,
      apiData: null,
      activeTopCategory: null,
      activeSubcategory: null,
      activeItem: null,
      menuLevel: null,
    });
  };

  const storeOrderDateInStorage = (orderTime) => {
    const orderDate = new Date(orderTime);
    const orderDateToStore = {
      value: orderDate.getMonth() + "-" + orderDate.getDate(), // the day of a date as a number (1-31)
      displayValue:
        orderDate.getDate() === new Date().getDate()
          ? appLabels["order"]["today"]
          : prettifyDate(orderDate, appLanguage), // stringified Date object
      dateObject: orderDate,
    };

    localforage.setItem(skin + "__orderDate", orderDateToStore);
  };

  const updateOrderTime = async (foundStore) => {
    /* Set the order time as now + min interval */
    let foundStoreWithVexilorConfig = null;
    if (foundStore && !foundStore.vexilorConfig) {
      foundStoreWithVexilorConfig = await getStore1306Config(skin, appLanguage, foundStore);
    }

    const storeConfig = foundStoreWithVexilorConfig
      ? foundStoreWithVexilorConfig.vexilorConfig
      : storeContext.activeOrderStore.vexilorConfig;

    const formattedStoreOverrides =
      orderType === "dinein"
        ? []
        : formatStoreTimeOverrides(
            orderType === "delivery"
              ? storeConfig.delivery_min_interval_override
              : storeConfig.pickup_min_interval_override,
            new Date()
          );
    const blockedTimeIntervals =
      Object.keys(formattedStoreOverrides).length === 0
        ? []
        : formattedStoreOverrides[getDayOfWeekFromIndex(new Date().getDay())].filter(
            (interval) => interval.isBlocked
          );

    if (storeConfig) {
      triggerMenuRef.current = false;
      const minInterval = getMinInterval(storeConfig, orderType, skin);

      const orderTime = new Date();
      orderTime.setTime(orderTime.getTime() + minInterval * 60000);
      if (
        foundStore.isOpen &&
        isValidOrderTime(foundStoreWithVexilorConfig, {
          value: {
            value: orderTime,
            trueBusinessDate: getTrueBusinessDate(orderTime, foundStoreWithVexilorConfig),
          },
        })
      ) {
        if (
          !isTimeSlotBlocked(new Date(orderTime), blockedTimeIntervals) &&
          isValidOrderTime(foundStoreWithVexilorConfig, {
            value: {
              value: orderTime,
              trueBusinessDate: getTrueBusinessDate(orderTime, foundStoreWithVexilorConfig),
            },
          })
        ) {
          orderTimeContext.update({
            value: new Date(orderTime),
            displayValue:
              minInterval >= 1440 // if minimum interval is more than 24 hrs, do not use ASAP
                ? `${getTimeAmPm(orderTime)}`
                : `${appLabels["order"]["asap"]} (~${minInterval} ${appLabels["order"]["minutes-short-hand"]})`,
            triggerMenu: true,
            trueBusinessDate: getTrueBusinessDate(orderTime, foundStore),
          });
          storeOrderDateInStorage(orderTime);
          localforage.setItem(
            skin + "__trueBusinessDate",
            getTrueBusinessDate(orderTime, foundStore)
          );
        } else {
          //if the order time falls in a blocked time interval
          //calculate the next available time slot after the block finishes

          let lookingForNextAvailableTime = true;

          let now = new Date();
          let iterationIndex = 0;
          const pickupIncrement = storeConfig.pickup_inc_interval;
          while (lookingForNextAvailableTime) {
            const roundedMinutes = roundToNearestQuarter(now.getMinutes());
            const roundedTime = roundTime(now, roundedMinutes, true);

            const increment = getIntermittentIncrementInterval(
              formattedStoreOverrides,
              roundedTime,
              pickupIncrement
            );
            const nextTime = newTime(roundedTime, iterationIndex === 0 ? minInterval : increment); // "In {pickupMinInterval OR pickupIncrement} min"
            const nextTimeWithResetSeconds = new Date(nextTime);
            nextTimeWithResetSeconds.setSeconds(0, 0); // reset seconds to 0 to allow pre-select based on storage value

            const nextTimeWithResetSecondsWithInterval = new Date(nextTimeWithResetSeconds);
            nextTimeWithResetSecondsWithInterval.setTime(
              nextTimeWithResetSecondsWithInterval.getTime() + minInterval * 60000
            );

            if (
              !isTimeSlotBlocked(nextTimeWithResetSeconds, blockedTimeIntervals) &&
              isValidOrderTime(foundStoreWithVexilorConfig, {
                value: {
                  value: nextTimeWithResetSeconds,
                  trueBusinessDate: getTrueBusinessDate(
                    nextTimeWithResetSeconds,
                    foundStoreWithVexilorConfig
                  ),
                },
              }) &&
              isValidOrderTime(foundStoreWithVexilorConfig, {
                value: {
                  value: nextTimeWithResetSecondsWithInterval,
                  trueBusinessDate: getTrueBusinessDate(
                    nextTimeWithResetSecondsWithInterval,
                    foundStoreWithVexilorConfig
                  ),
                },
              }) &&
              isWithinBusinessHours(foundStoreWithVexilorConfig, nextTimeWithResetSeconds)
            ) {
              orderTimeContext.update({
                value: new Date(nextTimeWithResetSecondsWithInterval),
                displayValue: `${getTimeAmPm(nextTimeWithResetSecondsWithInterval)}`,
                triggerMenu: true,
                trueBusinessDate: getTrueBusinessDate(
                  nextTimeWithResetSecondsWithInterval,
                  foundStore
                ),
              });
              lookingForNextAvailableTime = false;
              storeOrderDateInStorage(nextTimeWithResetSecondsWithInterval);
              localforage.setItem(
                skin + "__trueBusinessDate",
                getTrueBusinessDate(nextTimeWithResetSecondsWithInterval, foundStore)
              );
              break;
            } else {
              now = new Date(nextTimeWithResetSeconds);
            }
            iterationIndex++;

            // fail safe to break out of the loop
            if (iterationIndex > 1000) {
              break;
            }
          }
        }
      } else {
        // Store is closed and/or ordering for future date
        let dayInFutureIndex = 0;

        /** if ordering for a future date, use the default min pickup/delivery interval */
        let defaultMinInterval =
          orderType === "delivery"
            ? storeConfig.delivery_min_interval
            : storeConfig.pickup_min_interval;

        let today = new Date();
        let futureDate = new Date();
        let nextDay = new Date();
        nextDay.setDate(today.getDate() + dayInFutureIndex);
        futureDate = nextDay;
        let selectedDay = futureDate;

        let selectedDayApiOpeningTime = getOpeningTime(foundStore.hours, selectedDay.getDay());
        let selectedDayApiClosingTime = getClosingTime(foundStore.hours, selectedDay.getDay());

        let realHours = getFormattedStoreHoursWithMidnight(
          selectedDayApiOpeningTime,
          selectedDayApiClosingTime,
          futureDate,
          foundStoreWithVexilorConfig
        );
        //futureDate.setTime(tempSelectedDayStoreHours.formattedOpeningTime.getTime());

        let todayStoreHours = getFormattedStoreHoursWithMidnight(
          selectedDayApiOpeningTime,
          selectedDayApiClosingTime,
          today,
          foundStoreWithVexilorConfig
        );

        let futureDatePlusMinInterval = new Date();
        futureDatePlusMinInterval.setTime(today.getTime() + minInterval * 60000);

        while (
          (isNaN(selectedDayApiOpeningTime.replace(":", "")) &&
            selectedDayApiOpeningTime !== "closed") ||
          (isNaN(selectedDayApiClosingTime.replace(":", "")) &&
            selectedDayApiClosingTime !== "closed") ||
          (realHours !== "closed" && isNaN(realHours.formattedClosingTime.getTime())) ||
          (realHours !== "closed" && isNaN(realHours.formattedOpeningTime.getTime())) ||
          realHours === "closed" ||
          foundStoreWithVexilorConfig.hours[
            getDayOfWeekFromIndex(today.getDay() + dayInFutureIndex)
          ] === "closed" ||
          (todayStoreHours !== "closed" &&
            futureDate.getTime() >= todayStoreHours.formattedClosingTime.getTime() &&
            futureDate.getDate() === todayStoreHours.formattedClosingTime.getDate()) ||
          today.getTime() > futureDate.getTime() ||
          !isValidOrderTime(foundStoreWithVexilorConfig, {
            value: {
              value: futureDatePlusMinInterval,
              trueBusinessDate: getTrueBusinessDate(
                futureDatePlusMinInterval,
                foundStoreWithVexilorConfig
              ),
            },
          })
        ) {
          dayInFutureIndex++;
          let nextDay = new Date();
          nextDay.setDate(today.getDate() + dayInFutureIndex);
          futureDate = nextDay;
          selectedDayApiOpeningTime = getOpeningTime(foundStore.hours, futureDate.getDay());
          selectedDayApiClosingTime = getClosingTime(foundStore.hours, futureDate.getDay());

          realHours = getFormattedStoreHoursWithMidnight(
            selectedDayApiOpeningTime,
            selectedDayApiClosingTime,
            futureDate,
            foundStoreWithVexilorConfig
          );
          if (realHours === "closed") {
            futureDate.setTime(
              new Date(new Date(futureDate).setDate(futureDate.getDate() + 1)).getTime()
            );
          } else {
            futureDate.setTime(realHours.formattedOpeningTime.getTime());
          }

          futureDatePlusMinInterval.setTime(futureDate.getTime() + minInterval * 60000);

          if (dayInFutureIndex >= 7) {
            break;
          }
        }

        selectedDayApiOpeningTime = getOpeningTime(
          foundStoreWithVexilorConfig.hours,
          futureDate.getDay()
        );
        selectedDayApiClosingTime = getClosingTime(
          foundStoreWithVexilorConfig.hours,
          futureDate.getDay()
        );

        const selectedDayStoreHours = getFormattedStoreHoursWithMidnight(
          selectedDayApiOpeningTime,
          selectedDayApiClosingTime,
          futureDate,
          foundStore
        );

        let selectedDayStoreOpeningTime = selectedDayStoreHours.formattedOpeningTime;
        let selectedDayStoreOpeningTimeWithMinInterval = new Date(selectedDayStoreOpeningTime);
        selectedDayStoreOpeningTimeWithMinInterval.setTime(
          selectedDayStoreOpeningTimeWithMinInterval.getTime() + defaultMinInterval * 60000
        );

        /** Make sure when the blocked intervals are being checked, they have the same date as the calculated time
         * otherwise isTimeSlotBlocked could return incorrect results
         */
        let blockedTimeIntervalsForTheSelectedDay =
          Object.keys(formattedStoreOverrides).length === 0
            ? []
            : formattedStoreOverrides[
                getDayOfWeekFromIndex(new Date(selectedDayStoreOpeningTimeWithMinInterval).getDay())
              ].filter((interval) => interval.isBlocked);

        if (blockedTimeIntervalsForTheSelectedDay.length > 0) {
          blockedTimeIntervalsForTheSelectedDay.forEach((blockedInterval) => {
            blockedInterval.end.setDate(selectedDayStoreOpeningTimeWithMinInterval.getDate());
            blockedInterval.end.setMonth(selectedDayStoreOpeningTimeWithMinInterval.getMonth());
            blockedInterval.end.setFullYear(
              selectedDayStoreOpeningTimeWithMinInterval.getFullYear()
            );

            blockedInterval.start.setDate(selectedDayStoreOpeningTimeWithMinInterval.getDate());
            blockedInterval.start.setMonth(selectedDayStoreOpeningTimeWithMinInterval.getMonth());
            blockedInterval.start.setFullYear(
              selectedDayStoreOpeningTimeWithMinInterval.getFullYear()
            );
          });
        }

        while (
          isTimeSlotBlocked(
            new Date(selectedDayStoreOpeningTimeWithMinInterval),
            blockedTimeIntervalsForTheSelectedDay
          )
        ) {
          selectedDayStoreOpeningTimeWithMinInterval.setTime(
            selectedDayStoreOpeningTimeWithMinInterval.getTime() + defaultMinInterval * 60000
          );
          if (blockedTimeIntervalsForTheSelectedDay.length > 0) {
            blockedTimeIntervalsForTheSelectedDay.forEach((blockedInterval) => {
              blockedInterval.end.setDate(selectedDayStoreOpeningTimeWithMinInterval.getDate());
              blockedInterval.end.setMonth(selectedDayStoreOpeningTimeWithMinInterval.getMonth());
              blockedInterval.end.setFullYear(
                selectedDayStoreOpeningTimeWithMinInterval.getFullYear()
              );

              blockedInterval.start.setDate(selectedDayStoreOpeningTimeWithMinInterval.getDate());
              blockedInterval.start.setMonth(selectedDayStoreOpeningTimeWithMinInterval.getMonth());
              blockedInterval.start.setFullYear(
                selectedDayStoreOpeningTimeWithMinInterval.getFullYear()
              );
            });
          }
        }

        orderTimeContext.update({
          value: new Date(selectedDayStoreOpeningTimeWithMinInterval),
          displayValue: getTimeAmPm(selectedDayStoreOpeningTimeWithMinInterval),
          triggerMenu: true,
          trueBusinessDate: getTrueBusinessDate(
            selectedDayStoreOpeningTimeWithMinInterval,
            foundStore
          ),
        });
        storeOrderDateInStorage(selectedDayStoreOpeningTimeWithMinInterval);

        localforage.setItem(
          skin + "__trueBusinessDate",
          getTrueBusinessDate(selectedDayStoreOpeningTimeWithMinInterval, foundStore)
        );
      }

      //Check if the session storage to detect if user needs to be redirected to an item page instead of the menu
      const clickedOnFeaturedItem = sessionStorage.getItem(skin + "__featuredItemLink") !== null;
      const handlingReOrderData = sessionStorage.getItem(skin + "__tempReorderData") !== null;
      if (clickedOnFeaturedItem) {
        const featuredItemLink = sessionStorage.getItem(skin + "__featuredItemLink");
        sessionStorage.removeItem(skin + "__featuredItemLink");
        sessionStorage.removeItem(skin + "__featuredItemStoreId");
        resetMenu();
        await storeContext.updateActiveOrderStore(foundStoreWithVexilorConfig);
        setTimeout(() => {
          history.push({
            pathname: featuredItemLink,
            state: { from: "menu-slider" },
          });
        }, 500);
      } else if (handlingReOrderData) {
        if (userDeliveryAddress && orderType === "delivery") {
          localforage
            .setItem(skin + "__userDeliveryAddress", userDeliveryAddress)
            .then(async () => {
              resetMenu();
              await storeContext.updateActiveOrderStore(foundStoreWithVexilorConfig);
              setIsHandlingReorder(true);
            });
        } else {
          resetMenu();
          await storeContext.updateActiveOrderStore(foundStoreWithVexilorConfig);
          setIsHandlingReorder(true);
        }
      } else {
        //if order type is delivery, store the delivery address in local forage before continuing
        if (userDeliveryAddress && orderType === "delivery") {
          localforage
            .setItem(skin + "__userDeliveryAddress", userDeliveryAddress)
            .then(async () => {
              resetMenu();
              await storeContext.updateActiveOrderStore(foundStoreWithVexilorConfig);
              setTimeout(() => {
                history.push("/online-ordering/menu");
              }, 500);
            });
        } else {
          resetMenu();
          await storeContext.updateActiveOrderStore(foundStoreWithVexilorConfig);
          setTimeout(() => {
            history.push("/online-ordering/menu");
          }, 500);
        }
      }
    }
  };

  const handleSelectingActiveStore = (store) => {
    const isCart = cartData && cartData.quantity > 0;
    localforage.getItem(skin + "__activeOrderStore").then((storedOrderStore) => {
      const storeGotChanged = storedOrderStore && storedOrderStore.storeId !== store.storeId;
      if (isCart && storeGotChanged) {
        setModalContent({
          content: (
            <div className="confirm-order-type__container">
              <p>
                {appLabels["order"]["order-type-change-confirmation"].split("[order-type]")[0]}
                <strong>{store.name}</strong>
                {appLabels["order"]["order-type-change-confirmation"].split("[order-type]")[1]}
              </p>
              <p>{appLabels["order"]["your-current-order-will-be-cleared"]}</p>
            </div>
          ),
          onConfirm: () => {
            cartData.updateCart(null);
            updateOrderTime(store);
          },
        });
        setIsShowingCartClearPopup(true);
      } else {
        updateOrderTime(store);
      }
    });
  };

  const updateActiveStore = (store, isFavStore, setShowLoadingDots) => {
    if (setShowLoadingDots) {
      setShowLoadingDots(true);
    }

    if (orderType === "delivery") {
      if ((Object.keys(userLocation).length === 0 || !userLocation.lat) && !isStadiumSchema) {
        //no address entered yet
        setNoAddressEntered(true);
        if (setShowLoadingDots) {
          setShowLoadingDots(false);
        }
      } else if (isFavStore && currentStoresSubFilter === "favorites") {
        //calculate the distance between the user and this store and compare it to max_search_distance
        const storeCoordsObject = new google.maps.LatLng(userLocation.lat, userLocation.lng);
        reverseGeoCode(storeCoordsObject).then((reverseGeoCodeResponse) => {
          const reverseGeoCodeComponents = reverseGeoCodeResponse["address_components"];
          const postalCodeComponent = reverseGeoCodeComponents.filter(
            (component) =>
              component["types"].includes("postal_code") &&
              !component["types"].includes("postal_code_prefix")
          );
          const postalCode =
            postalCodeComponent.length > 0 ? postalCodeComponent[0]["long_name"] : "";

          let storeIsInRange = true;
          setIsCalling1300(true);
          get1300Stores(
            skin,
            orderType,
            appLanguage,
            userLocation.lat,
            userLocation.lng,
            postalCode
          ).then((apiStores) => {
            if (apiStores) {
              storeIsInRange = !!apiStores.filter(
                (tempStore) => tempStore.storeId === store.storeId
              ).length;

              if (!storeIsInRange) {
                setDeliveryStoreNotInRange(true);
                if (setShowLoadingDots) {
                  setShowLoadingDots(false);
                }
                return;
              } else {
                handleSelectingActiveStore(store);
              }
            }
            setIsCalling1300(false);
          });
        });
      } else {
        handleSelectingActiveStore(store);
      }
    } else {
      handleSelectingActiveStore(store);
    }
  };

  useEffect(() => {
    if (
      userLocation &&
      !!Object.keys(userLocation).length &&
      userLocation.lat &&
      userSelectedAddressPrediction !== null &&
      !isCalling1300
    ) {
      if (orderType === "pickup") {
        getStoresInRange();
      }
    }
  }, [userLocation, userSelectedAddressPrediction]);

  useEffect(() => {
    if (
      state &&
      (state.from === "confirm-address" ||
        state.from === "saved-delivery-address" ||
        state.from === "stadium-section")
    ) {
      if (isStadiumSchema) {
        setUserDeliveryAddress(state.userAddress);
      } else {
        setUserDeliveryAddress(state.userAddress);
        updateUserLocation(state.userCoordinates.lat, state.userCoordinates.lng);
        setUserSelectedAddressPrediction(state.userCoordinates.placeId);
      }
    }
  }, []);

  useEffect(() => {
    if (orderType === "delivery" && userDeliveryAddress && userLocation) {
      findDeliveryStores();
    }
  }, [orderType, userDeliveryAddress, userLocation]);

  useEffect(() => {
    localforage.getItem(skin + "__customerID").then((storedCustomerID) => {
      if (storedCustomerID) setCustomerID(storedCustomerID);
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      if (customerID) {
        updateCustomerFavorite(
          skin,
          appLanguage,
          loginToken,
          customerID,
          "get",
          [],
          "LOCATIONS",
          []
        )
          .then((favoriteLocations) => {
            if (favoriteLocations) {
              if (favoriteLocations.length === 0) {
                setFavoriteLocations([]);
              } else {
                setFavoriteLocations(favoriteLocations);
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else {
      setFavoriteLocations([]);
    }
  }, [isLoggedIn, customerID]);

  /** Use Effect to handle reordering passed orders, it will verify the items before adding them to the cart */
  useEffect(() => {
    if (
      isHandlingReorder &&
      menuContext &&
      menuContext.apiData &&
      storeContext &&
      storeContext.activeOrderStore
    ) {
      (async () => {
        const orderData = JSON.parse(sessionStorage.getItem(skin + "__tempReorderData"));
        let filteredOrderWithDetails = {};
        if (!orderData.item) {
          const itemDetails = await getOrderItemDetails(
            skin,
            orderData.id,
            orderData.storeLocation.id,
            appLanguage
          );
          if (itemDetails) {
            filteredOrderWithDetails = jsonCopy(orderData);
            filteredOrderWithDetails.discountTotal = itemDetails.discountTotal;
            filteredOrderWithDetails.items = itemDetails.items;
            filteredOrderWithDetails.paymentDetails = itemDetails.paymentDetails;
            filteredOrderWithDetails.rewardDetails = itemDetails.rewardDetails;
            filteredOrderWithDetails.orderAddress = itemDetails.orderAddress;
          }
        } else {
          filteredOrderWithDetails = orderData;
        }

        const availableOrderItems = await checkAvailableOrderItems(
          menuContext.menuLevel,
          menuContext.apiData,
          filteredOrderWithDetails,
          appLanguage,
          storeContext.activeOrderStore.storeId,
          orderType,
          orderTimeContext,
          skin,
          isAddonQuantityEnabled
        );

        let outOfStockItems = jsonCopy(availableOrderItems).filter((menuItem) =>
          checkOutOfStockItem(menuItem)
        );

        if (availableOrderItems.length > 0 && availableOrderItems.length > outOfStockItems.length) {
          const tempCart = cartData.value ? [...cartData.value] : []; // get cart from storage, if doesn't exist start a fresh array

          const filteredUnavailableItems = getUnavailableItems(
            filteredOrderWithDetails,
            availableOrderItems
          );

          const outOfStockItemsWithUnavailbleItems = [
            ...outOfStockItems,
            ...filteredUnavailableItems,
          ];

          if (outOfStockItemsWithUnavailbleItems.length > 0) {
            const filteredOutOfStockItems = outOfStockItemsWithUnavailbleItems.map(
              (outOfStockItem) => {
                return {
                  id: outOfStockItem.id,
                  name: outOfStockItem.name,
                  item_count: Number(outOfStockItem.item_count),
                  quantity: outOfStockItem.quantity,
                  itemOutOfStock: outOfStockItem.itemUnavailable ? false : true,
                  itemUnavailable: outOfStockItem.itemUnavailable
                    ? outOfStockItem.itemUnavailable
                    : false,
                };
              }
            );

            localforage.setItem(skin + "__outOfStockItems", filteredOutOfStockItems);
          }

          //assign the group id to each combo child item as well as formatting their addon/modifier groups
          availableOrderItems.forEach((reOrderItem, index) => {
            if (reOrderItem.combo_child_items) {
              reOrderItem.combo_child_items = formatItems(reOrderItem.combo_child_items);
            }
            // validate the item quantity
            if (isValidItemQuantity(tempCart, reOrderItem)) {
              reOrderItem.entryKey = Date.now() + index;
              onAddProductToCartXtreme(
                reOrderItem,
                reOrderItem.category,
                reOrderItem.subcategory,
                skin
              );
              tempCart.push(reOrderItem);
            } else if (checkLimitedStockedItemToCart(reOrderItem)) {
              reOrderItem.entryKey = Date.now() + index;
              reOrderItem.quantity = reOrderItem.item_count;
              onAddProductToCartXtreme(
                reOrderItem,
                reOrderItem.category,
                reOrderItem.subcategory,
                skin
              );
              tempCart.push(reOrderItem);
            }
          });

          cartData.updateCart(tempCart);
          setTimeout(() => {
            history.push("/online-ordering/menu");
          }, 500);
        } else {
          setIsInvalidOrder(true);
        }

        sessionStorage.removeItem(skin + "__tempReorderData");
        setIsHandlingReorder(false);
      })();
    }
  }, [isHandlingReorder, menuContext, storeContext]);

  const getUseMyLocationText = () => {
    return (
      <>
        <p className="order-stores__communication-blurb order-stores__communication-blurb-inline">
          {
            appLabels["order"]["no-nearby-locations-blurb"].split(
              `'${appLabels["order"]["use-my-location"]}'`
            )[0]
          }
          <button
            onClick={() => {
              getUserGeolocation();
            }}
            className="button order-stores__use-location-button order-stores__use-location-button-inline"
            aria-label={appLabels["general"]["find-location-google-maps"]}
            type="button"
          >
            <LocationSearchingTwoTone className="icon--target" fontSize="small" />
            <span style={{ fontSize: "1.0rem", color: secondaryColor }}>
              {appLabels["order"]["use-my-location"]}
            </span>
          </button>
        </p>
      </>
    );
  };

  /** Call geolocation function to get the nearby stores by default */
  useEffect(() => {
    if (orderType === "pickup" && !isStadiumSchema) {
      const storedState = sessionStorage.getItem(skin + "__orderStoreState");
      if (storedState) {
        sessionStorage.removeItem(skin + "__orderStoreState");
        const parsedState = JSON.parse(storedState);
        setCurrentStoresFilter(parsedState.screen);
        setStoreOnImpression(parsedState.storeOnImpression);
        if (parsedState.enteredAddress === "geoLocation") {
          getUserGeolocation();
        } else {
          onSearchBoxPlacesChanged(parsedState.enteredAddress);
        }
      } else {
        getUserGeolocation();
      }
    }
  }, []);

  useEffect(() => {
    if (currentStoresFilter === "list") {
      setStoreOnImpression(null);
    }
  }, [currentStoresFilter]);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      "& .MuiTabs-root": {
        maxWidth: "1200px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        paddingLeft: deviceWidth >= 660 ? "24px" : "0",
        paddingRight: deviceWidth >= 660 ? "24px" : "0",
      },
    },
    tabs: {
      "& .MuiTabs-indicator": {
        backgroundColor: "black",
        height: 4,
        maxWidth: "600px",
        width: "100%",
      },
      "& .MuiTabs-root": {
        textTransform: "none",
      },
      "& .MuiTab-root": {
        color: "black",
        textTransform: "none",
      },
      "& .Mui-selected": {
        color: "black",
        fontWeight: "bold",
      },
      "& .MuiButtonBase-root": {
        maxWidth: "600px",
        display: "inline-flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      },
      "& .MuiTabs-flexContainer": {
        display: "flex",
        justifyContent: `${isStadiumSchema ? "space-between" : "center"}`,
      },
      "& .MuiTab-wrapper": {
        fontSize: "16px",
      },
    },
    tab1: {
      "& .MuiTab-wrapper": {
        width: "100%",
        [theme.breakpoints.up(660)]: {
          width: "50%",
          display: "inline-flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "flex-end",
        },
      },
    },
    tab2: {
      "& .MuiTab-wrapper": {
        width: "100%",
        maxWidth: "600px",
        [theme.breakpoints.up(660)]: {
          width: "50%",
          display: "inline-flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "flex-start",
        },
      },
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (orderType === "delivery") {
      /** When user lands here for delivery, clear the current active order store */
      storeContext.updateActiveOrderStore(null);
    }
  }, []);

  return (
    <>
      {deviceWidth >= 660 && orderType === "pickup" && (
        <div className="delivery-address__header-container desktop-container">
          <h2 style={{ marginBottom: "0", marginTop: "20px" }}>
            {appLabels["order"]["select-store"]}
          </h2>
        </div>
      )}

      {orderType === "pickup" && !isStadiumSchema && (
        <div>
          <OrderStoresSearchComponent
            onSearchBoxPlacesChanged={onSearchBoxPlacesChanged}
            searchboxInputRef={searchboxInputRef}
            autocompleteRef={autocompleteRef}
            isAddressValid={isAddressValid}
            getUserGeolocation={getUserGeolocation}
            geoLocationButtonIsActive={geoLocationButtonIsActive}
            orderType={orderType}
          />
        </div>
      )}

      {orderType === "delivery" && userDeliveryAddress && (
        <div
          className="orderDetailsSummary__container"
          style={{ paddingTop: "5px", paddingBottom: "5px" }}
        >
          <div
            className="orderDetailsSummary__location-details desktop-container"
            style={{ border: "none" }}
          >
            {isStadiumSchema ? (
              <p>
                <span>{appLabels["order"]["delivering-to-address"]}:</span>
                <br />
                <span className="stadium-delivery-address">
                  {appLabels["form"]["section"]} {userDeliveryAddress.split("/")[0].toUpperCase()},{" "}
                  {appLabels["form"]["row"]} {userDeliveryAddress.split("/")[1].toUpperCase()},{" "}
                  {appLabels["form"]["seat"]} {userDeliveryAddress.split("/")[2].toUpperCase()}
                </span>
              </p>
            ) : (
              <div className="delivery-address-detail-container flex__column">
                <p>
                  <span style={{ marginBottom: "20px" }}>
                    {appLabels["order"]["delivering-to-address"]}:
                  </span>
                </p>
                <p>
                  {userDeliveryAddress["first-address"]},{" "}
                  {!!userDeliveryAddress.city && userDeliveryAddress.city + ","}{" "}
                  {userDeliveryAddress.province &&
                  userDeliveryAddress.province.split(":").length === 1
                    ? userDeliveryAddress.province + ","
                    : ""}{" "}
                  {!!userDeliveryAddress.postal && userDeliveryAddress.postal}
                  <br />
                  {userDeliveryAddress["second-address"] && (
                    <span className="address-validation__apt-suite-unit-container">
                      <span className="address-validation__apt-suite-unit-header">
                        {appLabels["form"]["apt-suit-unit"]}
                      </span>
                      <span className="address-validation__apt-suite-unit">{` ${userDeliveryAddress["second-address"]}`}</span>
                    </span>
                  )}
                </p>
              </div>
            )}

            {showEditButtons && (
              <Link
                ref={homeLinkRef}
                to={
                  isStadiumSchema
                    ? "/online-ordering/seat-section"
                    : "/online-ordering/delivery-address"
                }
                style={{ color: secondaryColor }}
                aria-label={appLabels["order"]["edit-delivery-address"]}
              >
                <IconEdit />
              </Link>
            )}
          </div>
        </div>
      )}

      {deviceWidth >= 660 && orderType === "delivery" && (
        <div className="delivery-address__header-container desktop-container">
          <h2 style={{ marginBottom: "0", marginTop: "20px" }}>
            {appLabels["order"]["select-store"]}
          </h2>
        </div>
      )}

      {/** Store Filters */}
      {!isStadiumSchema && (
        <div className="order-stores__delivery-filter-container">
          <div className="desktop-container">
            <button
              type="button"
              className={`order-stores__filter-button order-stores__filter-list-map-button button--primary button--primary-with-border button--primary-small button--primary-order-store-filter`}
              onClick={() => updateStoresFilter("list")}
              style={
                currentStoresFilter === "list"
                  ? {
                      color: getContrastColor(branding["primary-colour"]),
                      fontWeight: "500",
                      backgroundColor: branding["primary-colour"],
                      zIndex: 999,
                      borderColor: branding["primary-colour"],
                    }
                  : {
                      color: "#2a2a2a",
                      zIndex: 998,
                      border: "none",
                      backgroundColor: "var(--color-gray-light)",
                    }
              }
            >
              {" "}
              <ListIcon
                fontSize="large"
                style={{
                  color:
                    currentStoresFilter === "list"
                      ? getContrastColor(branding["primary-colour"])
                      : "#2a2a2a",
                }}
              />
              <span>{appLabels["order"]["store-filter-list"]}</span>
            </button>
            <button
              type="button"
              className={`order-stores__filter-button order-stores__filter-list-map-button button--primary button--primary-with-border button--primary-small button--primary-order-store-filter`}
              onClick={() => updateStoresFilter("map")}
              style={
                currentStoresFilter === "map"
                  ? {
                      color: getContrastColor(branding["primary-colour"]),
                      fontWeight: "500",
                      backgroundColor: branding["primary-colour"],
                      zIndex: 999,
                      borderColor: branding["primary-colour"],
                    }
                  : {
                      color: "#2a2a2a",
                      zIndex: 998,
                      border: "none",
                      backgroundColor: "var(--color-gray-light)",
                    }
              }
            >
              <Map
                fontSize="large"
                style={{
                  color:
                    currentStoresFilter === "map"
                      ? getContrastColor(branding["primary-colour"])
                      : "#2a2a2a",
                }}
              />
              <span>{appLabels["order"]["store-filter-map"]}</span>
            </button>
          </div>
        </div>
      )}

      {orderType === "pickup" && currentStoresFilter === "list" && (
        <div
          className={`order-stores__filter-container ${classes.root} ${classes.tabs} ${
            isStadiumSchema ? " isStadium" : ""
          }`}
        >
          <div className="desktop-container">
            {isStadiumSchema ? (
              <Tabs value={currentStoresSubFilter}>
                <Tab
                  label={appLabels["order"]["select-store-all"]}
                  className={`order-stores__filter-button `}
                  style={
                    currentStoresSubFilter === "all"
                      ? {
                          color: "#2a2a2a",
                          fontWeight: "bold",
                        }
                      : { color: "var(--color-gray)" }
                  }
                  value={"all"}
                  onClick={() => updateStoresSubFilter("all")}
                />
                <Tab
                  label={appLabels["order"]["favorites"]}
                  className={`order-stores__filter-button `}
                  style={
                    currentStoresSubFilter === "favorites"
                      ? {
                          color: "#2a2a2a",
                          fontWeight: "bold",
                        }
                      : { color: "var(--color-gray)" }
                  }
                  value={"favorites"}
                  onClick={() => updateStoresSubFilter("favorites")}
                />
              </Tabs>
            ) : (
              <Tabs value={currentStoresSubFilter}>
                <Tab
                  label={appLabels["order"]["select-store-nearby"]}
                  className={`order-stores__filter-button `}
                  style={
                    currentStoresSubFilter === "nearby"
                      ? {
                          color: "#2a2a2a",
                          fontWeight: "bold",
                        }
                      : { color: "var(--color-gray)" }
                  }
                  value={"nearby"}
                  onClick={() => updateStoresSubFilter("nearby")}
                />
                <Tab
                  label={appLabels["order"]["favorites"]}
                  className={`order-stores__filter-button `}
                  style={
                    currentStoresSubFilter === "favorites"
                      ? {
                          color: "#2a2a2a",
                          fontWeight: "bold",
                        }
                      : { color: "var(--color-gray)" }
                  }
                  value={"favorites"}
                  onClick={() => updateStoresSubFilter("favorites")}
                />
                <Tab
                  label={appLabels["order"]["select-store-all"]}
                  className={`order-stores__filter-button `}
                  style={
                    currentStoresSubFilter === "all"
                      ? {
                          color: "#2a2a2a",
                          fontWeight: "bold",
                        }
                      : { color: "var(--color-gray)" }
                  }
                  value={"all"}
                  onClick={() => updateStoresSubFilter("all")}
                />
              </Tabs>
            )}
          </div>
        </div>
      )}

      <div
        className={`order-stores__stores-container ${
          isStadiumSchema ? " order-stores__stores-container-stadium" : ""
        } ${currentStoresFilter === "list" ? "desktop-container" : ""} ${
          currentStoresFilter === "map" && userLocation.lat && storesInRange !== null
            ? "order-stores__stores-container-map"
            : " desktop-container"
        }`}
      >
        {currentStoresSubFilter === "all" &&
          currentStoresFilter === "list" &&
          !isCalling1300 &&
          orderType === "pickup" && (
            <>
              {allStoresContext &&
                allStoresContext.map((store, index) => (
                  <React.Fragment key={index}>
                    <OrderStoreInfoCard
                      store={store}
                      userLocation={userLocation}
                      favoriteLocations={favoriteLocations}
                      setFavoriteLocations={setFavoriteLocations}
                      addLocationToFavorites={addLocationToFavorites}
                      removeLocationFromFavorites={removeLocationFromFavorites}
                      isStoreOnImpression={false}
                      updateActiveStore={updateActiveStore}
                      isShowingCartClearPopup={isShowingCartClearPopup}
                      checkLocalForageForFavStore={checkLocalForageForFavStore}
                      isCalling1327={isCalling1327}
                    />
                  </React.Fragment>
                ))}
            </>
          )}

        {!isCalling1300 && (
          <>
            {currentStoresFilter === "list" &&
              currentStoresSubFilter === "nearby" &&
              storesInRange &&
              storesInRange !== "idle" &&
              storesInRange.map((store, index) => (
                <React.Fragment key={index}>
                  <OrderStoreInfoCard
                    store={store}
                    userLocation={userLocation}
                    favoriteLocations={favoriteLocations}
                    setFavoriteLocations={setFavoriteLocations}
                    addLocationToFavorites={addLocationToFavorites}
                    removeLocationFromFavorites={removeLocationFromFavorites}
                    isStoreOnImpression={false}
                    updateActiveStore={updateActiveStore}
                    isShowingCartClearPopup={isShowingCartClearPopup}
                    checkLocalForageForFavStore={checkLocalForageForFavStore}
                    isCalling1327={isCalling1327}
                  />
                </React.Fragment>
              ))}

            {storesInRange !== "idle" &&
              currentStoresSubFilter === "nearby" &&
              currentStoresFilter === "list" && (
                <>
                  {storesInRange !== null ? (
                    <>
                      {storesInRange.length === 0 && (
                        <p className="order-stores__communication-blurb">
                          {orderType === "pickup" &&
                            appLabels["order"]["no-pickup-stores-within-range-message"].replace(
                              "[stores-range]",
                              STORE_RANGE / 1000
                            )}

                          {orderType === "delivery" &&
                            appLabels["order"]["no-delivery-stores-within-range"]}
                        </p>
                      )}

                      {storesInRange === "idle" && orderType === "pickup" && (
                        <>{getUseMyLocationText()}</>
                      )}
                    </>
                  ) : (
                    <>{orderType === "pickup" && <>{getUseMyLocationText()}</>}</>
                  )}
                </>
              )}

            {favoriteLocations &&
              currentStoresSubFilter === "favorites" &&
              currentStoresFilter === "list" &&
              favoriteLocations.map((store, index) => (
                <React.Fragment key={index}>
                  {getFavStoreDetails(store) && (
                    <OrderStoreInfoCard
                      store={getFavStoreDetails(store)}
                      userLocation={userLocation}
                      favoriteLocations={favoriteLocations}
                      setFavoriteLocations={setFavoriteLocations}
                      addLocationToFavorites={addLocationToFavorites}
                      removeLocationFromFavorites={removeLocationFromFavorites}
                      isStoreOnImpression={false}
                      updateActiveStore={updateActiveStore}
                      isShowingCartClearPopup={isShowingCartClearPopup}
                      checkLocalForageForFavStore={checkLocalForageForFavStore}
                      isCalling1327={isCalling1327}
                    />
                  )}
                </React.Fragment>
              ))}

            {favoriteLocations &&
              favoriteLocations.length === 0 &&
              currentStoresSubFilter === "favorites" && (
                <p className="order-stores__communication-blurb">
                  {appLabels["order"]["no-favorite-locations-blurb"]}
                </p>
              )}
          </>
        )}

        {currentStoresFilter === "map" && !isCalling1300 && (
          <>
            {userLocation.lat && storesInRange !== null ? (
              <>
                <GoogleMaps
                  userLocation={userLocation}
                  storesInRange={storesInRange}
                  setStoreOnImpression={setStoreOnImpression}
                  storeOnImpression={storeOnImpression}
                  favoriteLocations={favoriteLocations}
                />
                {storeOnImpression &&
                  storesInRange &&
                  storesInRange !== "idle" &&
                  !!storesInRange.filter(
                    (storeInRange) => storeInRange.storeId === storeOnImpression.storeId
                  ).length &&
                  storesInRange.map((store, index) => (
                    <React.Fragment key={index}>
                      {store.storeId === storeOnImpression.storeId && (
                        <div className="order-store__map-selected-store desktop-container">
                          <OrderStoreInfoCard
                            store={store}
                            userLocation={userLocation}
                            favoriteLocations={favoriteLocations}
                            setFavoriteLocations={setFavoriteLocations}
                            addLocationToFavorites={addLocationToFavorites}
                            removeLocationFromFavorites={removeLocationFromFavorites}
                            isStoreOnImpression={
                              storeOnImpression
                                ? storeOnImpression.storeId === store.storeId
                                : false
                            }
                            updateActiveStore={updateActiveStore}
                            isShowingCartClearPopup={isShowingCartClearPopup}
                            checkLocalForageForFavStore={checkLocalForageForFavStore}
                          />{" "}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
              </>
            ) : (
              <>{orderType === "pickup" && getUseMyLocationText()}</>
            )}
          </>
        )}

        {isCalling1300 && <LoadingSpinner />}
      </div>

      {isAddressValid === false && orderType !== "delivery" && (
        <DialogModal
          isConfirmText={false}
          message={appLabels["form"]["invalid-address-error"]}
          resetRemoveDialog={() => {
            setIsAddressValid(null);
          }}
          isHTMLContent={false}
        />
      )}

      {isAddressValid === false && orderType === "delivery" && (
        <DialogModal
          isConfirmText={false}
          message={appLabels["form"]["invalid-delivery-address-error"]}
          resetRemoveDialog={() => {
            setIsAddressValid(null);
          }}
          isHTMLContent={false}
        />
      )}

      {noAddressEntered && (
        <DialogModal
          isConfirmText={false}
          message={appLabels["order"]["type-in-address"]}
          resetRemoveDialog={() => {
            setNoAddressEntered(null);
          }}
          isHTMLContent={false}
        />
      )}

      {deliveryStoreNotInRange && (
        <DialogModal
          isConfirmText={false}
          message={appLabels["order"]["location-not-in-delivery-range"]}
          resetRemoveDialog={() => {
            setDeliveryStoreNotInRange(null);
          }}
          isHTMLContent={false}
        />
      )}

      {isGuestFavoriteAttempt && (
        <DialogModal
          isConfirmText={false}
          hideConfirmButton={true}
          description="Guest User attempting to set item as favourite"
          message={
            <div className="menu-item__favourite-dialog-modal">
              <div style={{ width: "100%" }}>
                <p style={{ fontSize: "1em", fontWeight: "normal" }}>
                  {appLabels["order"]["guest-attempt-favorite-location"]}
                </p>
              </div>
              <div className="menu-item__favourite-dialog-modal-buttons-wrapper">
                <button
                  onClick={() => {
                    sessionStorage.setItem(
                      skin + "__lastVisitedLink",
                      window.location.hash.split("#")[1]
                    );

                    let currentState = {
                      screen: currentStoresFilter,
                      storeOnImpression: storeOnImpression,
                      enteredAddress: userSelectedAddressPrediction,
                    };

                    sessionStorage.setItem(
                      skin + "__orderStoreState",
                      JSON.stringify(currentState)
                    );

                    localforage.setItem(skin + "__storeIdToBeFaved", storeIdToBeFaved);
                    history.push("/login-register");
                  }}
                  className="button dialog-modal__button  button--primary button--primary-small"
                  type="button"
                >
                  {appLabels["login-register"]["sign-in"]}
                </button>

                <button
                  onClick={() => {
                    sessionStorage.setItem(
                      skin + "__lastVisitedLink",
                      window.location.hash.split("#")[1]
                    );
                    localforage.setItem(skin + "__storeIdToBeFaved", storeIdToBeFaved);
                    history.push("/register");
                  }}
                  className="button dialog-modal__button  button--primary button--primary-small"
                  type="button"
                >
                  {appLabels["login-register"]["register"]}
                </button>
              </div>
            </div>
          }
          resetRemoveDialog={() => {
            setIsGuestFavoriteAttempt(false);
          }}
          isHTMLContent={true}
        />
      )}

      {isShowingCartClearPopup && (
        <DialogModal
          isConfirmText={true}
          isCancelConfirm={true}
          hideConfirmButton={false}
          message={modalContent.content}
          resetRemoveDialog={() => {
            setIsShowingCartClearPopup(false);
          }}
          isHTMLContent={true}
          confirmAction={modalContent.onConfirm}
        />
      )}

      {isInvalidOrder && (
        <DialogModal
          message={appLabels["account"]["recent-orders-item-doesnt-exist-message"]}
          resetRemoveDialog={() => {
            setIsInvalidOrder(false);
            setTimeout(() => {
              history.push("/online-ordering/menu");
            }, 500);
          }}
        />
      )}
    </>
  );
};
