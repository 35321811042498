export const generalLabels = {
  welcome: "Bem-vindo(a)",
  "loading-your-setup": "Carregando sua configuração",
  "good-morning": "Bom dia",
  "good-afternoon": "Boa tarde",
  "good-evening": "Boa noite",
  account: "Conta",
  "scan-in-store": "Ler",
  "my-account": "Minha Conta",
  rewards: "Fidelidade",
  "gift-cards": "Cartões-Presente",
  order: "Pedido",
  "order-now": "Peça Agora",
  "find-store": "Encontre uma Loja",
  "terms-and-conditions": "Termos e Condições",
  maintenance_message: "Este site está em manutenção. Voltaremos em breve...",
  "cookie-message":
    "Usamos cookies para habilitar sua experiência de compra e analisar o tráfego do site, e para ajudar a acelerar o uso do nosso site. [link] para saber mais sobre isso. Você consente com nossos cookies caso continue a utilizar nosso site.",
  "cookie-message-link-text": "Veja nossa política",
  "remove-all-cart-items-confirmation-message":
    "Tem certeza que deseja remove todos os itens do seu carrinho?",
  "dialog-modal-confirm": "Confirmar",
  "dialog-modal-cancel": "Cancelar",
  "dialog-modal-ok": "Ok",
  "expires-on": "Expira em",
  remove: "Remover",
  "credit-card": "Cartão de Crédito",
  "gift-card": "Cartão-Presente",
  optional: "Opcional",
  subtotal: "Subtotal",
  total: "Total",
  barcode: "Código de Barras",
  "something-went-wrong-error": "Algo deu errado. Por favor, tente novamente mais tarde.",
  "login-expired-modal-message":
    "Seu login expirou. Estamos te redirecionando de volta à página de login...",
  "add-to-home-screen-prompt": "Adicionar à tela inicial",
  "logging-you-out": "Saindo…",
  "disabled-geolocation-alert":
    "Para procurar as lojas próximas, ative os serviços de localização nas configurações do seu dispositivo e permita que o aplicativo use a sua localização.",
  "renew-expired-session-confirmation": "Sua sessão expirou, deseja renová-la?",
  "expired-session-logout-countdown": "Você será desconectado em [number] segundos",
  "your-last-visit": "Sua última visita",
  "new-version-message":
    "Uma nova atualização está disponível. Por favor, confirme para recarregar.",
  monday: "Segunda-feira",
  tuesday: "Terça-feira",
  wednesday: "Quarta-feira",
  thursday: "Quinta-feira",
  friday: "Sexta-feira",
  saturday: "Sábado",
  sunday: "Domingo",
  "default-loyalty-card": "Cartão Principal",
  "app-version": "Versão [number]",
  "error-code": "Código de Erro",
  "main-website": "Site Principal",
  applied: "Aplicado",
  "go-back": "Voltar",
  "close-dialog-modal": "Fechar página de diálogo",
  "close-modal": "Fechar página",
  close: "fechar",
  "close-disclaimer": "Fechar Aviso Lega",
  "close-install-prompt": "Fechar mensagem de instalação do aplicativo",
  hide: "Ocultar",
  show: "Mostrar",
  open: "Abrir",
  "language-dropdown": "lista suspensa de idioma",
  "show-info-active-store": "Mostrar informações para a loja atualmente ativa",
  "show-info-all-stores": "Mostrar informações para todas as lojas da área",
  "find-location-google-maps": "Encontre minha localização atual no Google Maps",
  "search-address": "Procure pelo endereço",
  "close-store-info": "Fechar informações da loja",
  options: "opções",
  "change-barcode-type-to": "Alterar o tipo de código de barras para [barcode-type]",
  "show-app-header-order-summary": "Mostrar horário do pedido e detalhes de localização",
  "hide-app-header-order-summary": "Ocultar horário do pedido e detalhes de localização",
  "language-dropdown-display-name": "Idioma",
  done: "Pronto",
  january: "Janeiro",
  february: "Fevereiro",
  march: "Março",
  april: "Abril",
  may: "Posso",
  june: "Junho",
  july: "Julho",
  august: "Agosto",
  september: "Setembro",
  october: "Outubro",
  november: "Novembro",
  december: "Dezembro",
  "client-gift-card-website-label": "Cartão-Presente",
  skip: "Pular",
  "go-to-cart": "Vá para o carrinho",
  "subscribe-to-notifications": "Inscreva-se para notificações push",
  "xp-subscribe-to-notifications-header": "Nunca perca!",
  "xp-subscribe-to-notifications-message":
    "Permita que [merchant-name] envie ofertas e descontos exclusivos.",
  select: "Selecione",
  "for-text": "para",
  "go-to-dashboard": "Ir para a dashboard",
  modify: "Modificar",
  checking: "Verificando",
  home: "Lar",
  "hide-menu": "Ocultar Menu",
  resources: "Recursos",
};
