export const accountLabels = {
  profile: "Profile",
  "log-out": "Sign out",
  "log-out-confirm-message": "Are you sure you want to sign out?",
  "personal-info": "Personal Information",
  "update-your-personal-info": "Update your personal info",
  update: "Update",
  updating: "Updating...",
  "change-password": "Change Password",
  "update-your-password": "Update your password",
  "current-username": "Current username",
  "current-password": "Current password",
  "new-password": "New password",
  "confirm-new-password": "Confirm new password",
  "changing-password": "Changing Password...",
  "payment-methods": "Payment Methods",
  "add-credit-card": "Add credit card",
  "save-card": "Save Card",
  "saving-card": "Saving Card...",
  "add-new-card": "Add a new card",
  "add-new-card-paragraph": "To add a new card, type in the card number or scan the barcode",
  "scan-barcode": "Scan Barcode",
  "scan-barcode-modal-heading": "Scan the barcode on your card",
  "add-card": "Add a Card",
  adding: "Adding...",
  added: "Added",
  "transaction-history": "Transaction History",
  "recent-orders": "Recent Orders",
  "recent-online-orders": "Recent Online Orders",
  "recent-orders-disclaimer":
    "Total from previous order may differ as prices are subject to change",
  "recent-orders-item-doesnt-exist-message": "One or more items from this order no longer exist",
  "no-previous-orders": "No previous orders",
  "recent-loyalty-transactions": "Recent Loyalty Transactions",
  "recent-card-transactions": "Recent Card Transactions",
  "pay-in-store": "Scan in location",
  "add-money": "Add Money",
  "add-money-submit-button": "Add",
  "refresh-code": "Refresh Code",
  "my-wallet": "My Wallet",
  card: "Card",
  "method-of-payment": "Method of Payment",
  "reload-amount": "Reload Amount",
  "auto-reload": "Auto Reload",
  "auto-reload-amount": "Auto Reload Amount",
  "when-balance-reaches": "When Balance Reaches",
  "choose-how-often-to-reload": "or choose how often to reload",
  "set-auto-reload": "Set Auto Reload",
  "button-remove-autoreload": "Remove auto reload",
  "update-auto-reload": "Update Auto Reload",
  "update-account-details": "Update Account Details",
  "enter-password-to-confirm-changes": "Enter password to confirm changes",
  "balance-transfer-to-card-number": "To your loyalty card",
  "balance-transfer-success-message": "Balance Successfully Transferred",
  "balance-transfer-new-points-balance": "Your new points balance is",
  "transfer-funds": "Transfer funds",
  "funds-transferred": "Funds Transferred",
  "balance-transfer-disclaimer":
    "After the funds have been transferred to your account, the Gift Card will become inactive.",
  "transfer-funds-blurb": "Have an existing Gift Card? Transfer funds to your account here.",
  "balance-transfer-to-gift-card": "To your gift card",
  "open-barcode-scanner": "Open barcode scanner",
  "auto-reload-schedule-with-threshold":
    "[auto-reload] is set to add [reload-amount] to this card when the balance reaches [threshold-amount] or less.",
  "auto-reload-schedule-weekly":
    "[auto-reload] is set to add [reload-amount] to this card [frequency].",
  "auto-reload-schedule-monthly":
    "[auto-reload] is set to add [reload-amount] to this card [frequency].",
  "order-status": "Order Status",
  "order-status-active-orders-blurb": "You have [number-of-orders] order(s) in progress",
  "order-details": "Order Details",
  "order-status-make-changes-blurb":
    "If you need to make any changes, please contact the location at [phone-number]",
  "order-placed": "Placed",
  "order-accepted": "Approved",
  "order-cancelled": "Cancelled",
  "order-preparing": "Preparing",
  "order-ready-for-pickup": "Ready for pickup",
  "order-on-its-way": "On its way",
  "order-complete": "Complete",
  "order-in-progress": "Order(s) in Progress",
  "past-orders": "Past Orders",
  "ordered-items": "Ordered Items",
  "order-received": "Received",
  "order-void": "Void",
  "order-rejected": "Declined",
  "order-prepared": "Prepared",
  "order-status-last-update-time": "Last updated at [time-stamp]",
  "order-reviewing": "Reviewing",
  "order-timestamp-at": "at",
  "my-orders": "My orders",
  "request-account-deletion": "Request Account Deletion",
  "account-deletion-email-subject": "Account Deletion Request - [customer_id]",
  "account-deletion-email-body":
    "INSTRUCTIONS:\nPlease send this email in order to submit a request to delete the following account at [merchant_name]:\n\nCUSTOMER ID:\n[customer_id]\n\nCUSTOMER User Name\n[customer_email]\n\nBy sending, you acknowledge that this request is irreversible and your [merchant_name] account along with your associated data will be permanently removed. You will receive a final email confirmation once your account has been successfully deleted.",
  "delete-account": "Delete Account",
  "delete-my-account": "Delete My Account",
  deleting: "Deleting...",
  "enter-password": "Enter Password",
  "delete-account-confirmation-header": "Confirmation",
  "delete-account-sorry-to-see-you-go": "Sorry to see you go!",
  "delete-account-permanently": "Are you sure you want to permanently delete your account?",
  "delete-account-confirm-deletion":
    "To confirm the deletion of your account, please enter your password below.",
  "delete-account-success-message": "Your account is successfully deactivated!",
  "delete-account-blurb1":
    "After [number-of-days] days, we will delete all records and personal data associated with your account.",
  "delete-account-blurb2":
    "We have sent you an email confirmation to the email address associated with your account",
  "remove-address-confirmation": "Are you sure you want to remove this address?",
  "add-delivery-address": "Add a Delivery Address",
};
