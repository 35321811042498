export const formLabels = {
  "card-details": "Detalhes do Cartão",
  "credit-card-number": "Número do Cartão de Crédito",
  "name-on-card": "Nome no cartão",
  "expiry-date": "Data de validade",
  cvv: "CVV",
  "save-credit-card": "Salvar cartão de crédito",
  "billing-address": "Endereço de Cobrança",
  "address-1": "Endereço",
  "address-2": "Complemento",
  country: "País",
  province: "Estado/Província",
  city: "Cidade",
  postal: "Código Postal/CEP",
  phone: "Telefone",
  email: "E-mail",
  "confirm-email": "Confirmar e-mail",
  password: "Senha",
  "confirm-password": "Confirmar senha",
  "keep-me-logged-in": "Lembrar senha",
  "first-name": "Nome",
  "last-name": "Sobrenome",
  "special-instructions": "Instruções especiais",
  "form-security-message": "Esta informação será salva e criptografada seguramente",
  "card-number": "Número do cartão",
  "card-description": "Descrição do cartão",
  required: "Obrigatório",
  "missing-required-fields-error": "Complete os campos acima",
  "missing-required-addons-modifiers-error": "Escolhas obrigatórias não selecionadas",
  "title-numbers-error": "Título não pode conter números",
  "first-name-numbers-error": "Nome não pode conter números",
  "middle-name-numbers-error": "Nome do meio não pode conter números",
  "last-name-numbers-error": "Sobrenome não pode conter números",
  "invalid-email-error": "Forneça um e-mail válido",
  "phone-only-numbers-error": "Telefone somente pode conter números",
  "invalid-card-number": "Forneça um número válido",
  "credit-card-numbers-error": "Nome do cartão de crédito não pode conter números",
  "invalid-date-error": "Forneça uma data válida",
  "invalid-cvv-error": "Forneça um CVV válido",
  "invalid-postal-error": "Forneça um código postal válido",
  "invalid-birthday-error": "Forneça uma data de nascimento válida",
  "password-char-length-error": "Deve ter pelo menos 8 caracteres de comprimento",
  "passwords-dont-match": "Senhas não correspondem",
  "emails-dont-match": "E-mails não correspondem",
  "city-numbers-error": "Cidade não pode conter números",
  "country-numbers-error": "País não pode conter números",
  "county-numbers-error": "Município não pode conter números",
  "select-one": "Selecione um",
  weekly: "Semanalmente",
  monthly: "Mensalmente",
  male: "Masculino",
  female: "Feminino",
  "other-gender": "Não binário",
  "yes-to-promo-email": "Sim",
  "no-to-promo-email": "Não",
  english: "Inglês",
  french: "Francês",
  spanish: "Espanhol",
  portuguese: "Português",
  "simplified-chinese": "Chinês (Simplificado)",
  "traditional-chinese": "Chinês (Tradicional)",
  "default-submit-button": "Enviar",
  "default-submit-button-success": "Enviado",
  "default-submit-button-processing": "Enviando...",
  "optional-label": "Opcional",
  "enter-in-input-mask-pattern-format": "Forneça no formato [mask-pattern]",
  "generic-fallback-api-error": "Algum erro aconteceu durante o envio, por favor, tente novamente",
  "invalid-address-error": "Forneça um endereço válido",
  "accept-terms-text": "Eu li, entendi, concordo e aceito os [terms-and-conditions] de uso.",
  "accept-terms-link-text": "Termos e condições",
  "add-new-gift-card-invalid-card-error":
    "Número do cartão está inválido, por favor, tente novamente.",
  "special-delivery-instructions-field-label": "Informações de Entrega",
  "zip-code": "Zip Code",
  "unit-number": "Complemento",
  "invalid-phone-number": "Invalid Phone Number",
  "incorrect-username-password": "Username/Password is incorrect",
  "save-credit-card-login-error":
    "Faça login ou crie uma conta para salvar seu cartão de crédito para um checkout rápido da próxima vez.",
  "invalid-delivery-address-error": "Por favor, forneça um endereço completo e válido",
  "street-address": "Endereço da Rua",
  "apt-suit-unit": "Apto, Suíte ou Casa",
  "primary-address": "Endereço primário",
  section: "Seção",
  row: "Fileira",
  seat: "Assento",
  "do-not-include-country-code": "Por favor, não inclua o código de país",
  "eGiftCard-amount-incorrect": "O valor inserido está incorreto",
  "eGiftCard-amount-not-numeric": "O valor deve conter apenas números",
  "eGiftCard-date-time-incorrect": "Data ou hora está incorreta",
  "eGiftCard-value-negative": "O valor deve ser maior que 0",
  "invalid-amount": "Quantidade inválida",
};
