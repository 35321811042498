//Libraries
import React, { useContext, useState, useEffect, useRef } from "react";
import localforage from "localforage";
import { Link, useHistory } from "react-router-dom";

//Context
import OrderTypeContext from "../OnlineOrdering/OrderTypeContext";
import OrderTimeContext from "../OnlineOrdering/OrderTimeContext";
import StoreContext from "../OnlineOrdering/StoreContext";
import BrandingContext from "../App/BrandingContext";
import AppLabelsContext from "../App/AppLabelsContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import AppLanguageContext from "../App/AppLanguageContext";
import OrderContext from "../App/OrderContext";
import AppSettingsContext from "../App/AppSettingsContext";
import CWS5ModuleContext from "../App/CWS5ModuleContext";

//Custom Hooks
import useWindowSize from "../_common/hooks/useWindowSize";
import useWindowPath from "../_common/hooks/useWindowPath";
import { useHideOrderModule } from "../App/useHideOrderModule";
import { useLocation } from "react-router-dom";

//Helper functions
import { getContrastColor } from "../_common/colorHelpers";
import { get1300Stores } from "../OnlineOrdering/GoogleMaps/get1300Stores";
import {
  getDayOfWeekFromIndex,
  getMonthFromIndex,
} from "../OnlineOrdering/Locations/helpers/isStoreOpenOrClosed";
import { capitalizeWords } from "../_common/stringHelpers";
import { getTranslatedOrderType } from "./helpers/orderSettingsHelpers";
import { getTrueBusinessDate, prettifyDate } from "../Dashboard/DashboardOrder/dateHelpers";
import { calculateNextAvailableOrderTime } from "../_common/PaymentHelpers";
import { isTouchDevice } from "../_common/helpers/isTouchDevice";

//UI Components
import { OrderSettingsStore } from "./OrderSettingsStore";
import { OrderSettingsTime } from "./OrderSettingsTime";
import { OrderSettingsCart } from "./OrderSettingsCart";
import { ReactComponent as IconArrowRight } from "../_common/icons/IconArrowRight.svg";
import { ReactComponent as IconArrowLeft } from "../_common/icons/IconArrowLeft.svg";

//CSS
import "./OrderSettingsComponent.css";

const OrderSettingsComponent = (props) => {
  const { customClass } = props;
  const history = useHistory();
  const deviceWidth = useWindowSize().width;
  const orderTypeContext = useContext(OrderTypeContext);
  const activeOrderType = orderTypeContext.value;
  const orderTimeContext = useContext(OrderTimeContext);
  const branding = useContext(BrandingContext);
  const storeContext = useContext(StoreContext);
  const appLabels = useContext(AppLabelsContext);
  const appLanguage = useContext(AppLanguageContext);
  const merchantConfigContext = useContext(MerchantConfigContext);
  const orderContext = useContext(OrderContext);
  const skin = merchantConfigContext.skin;
  const activeTableNumber = storeContext.activeOrderStoreTable;
  const appSettings = useContext(AppSettingsContext);
  const cws5ModuleContext = useContext(CWS5ModuleContext);
  const isTermsEnabled = appSettings["enable-terms"] === "Yes";
  const isStadiumSchema = merchantConfigContext.merchant.I55 === "stadium";

  const isOnlineOrderingEnabled = cws5ModuleContext.isOnlineOrderingEnabled;

  const generatedTimeOptions = useRef(false);
  const initialTimeAndDate = useRef(null);

  const [orderTimeOptions, setOrderTimeOptions] = useState(null);
  const [activeOrderTimeOption, setActiveOrderTimeOption] = useState(null);
  const [orderTimeToBeSelected, setOrderTimeToBeSelected] = useState(null);

  const textIconColor = getContrastColor(branding["online-order-settings-background-colour"]);

  const activeOrderStore = storeContext.activeOrderStore;
  const [openTimeSelectionPanel, setOpenTimeSelectionPanel] = useState(false);

  const [orderDateOptions, setOrderDateOptions] = useState(null);
  const [activeOrderDateOption, setActiveOrderDateOption] = useState(null);

  const SLIDER_RIGHT_PADDING = 10;
  const listRef = useRef(null);
  const currentTransformX = useRef(0);
  const totalSlideGroups = useRef(0);
  const [isLeftScrollPossible, setIsLeftScrollPossible] = useState(false);
  const [isRightScrollPossible, setIsRightScrollPossible] = useState(false);

  const arrowButtonsAreSetup = useRef(false);

  const { hideOrderModule } = useHideOrderModule();

  let [showTimePanel, setShowTimePanel] = useState(false);

  useEffect(() => {
    if (listRef.current && arrowButtonsAreSetup.current === false) {
      const list = listRef.current;
      const listWidth = list.getBoundingClientRect().width;
      if (list.scrollWidth - SLIDER_RIGHT_PADDING > listWidth) {
        arrowButtonsAreSetup.current = true;
        setIsRightScrollPossible(true);
      }
    }
  }, [openTimeSelectionPanel && orderTimeOptions]);

  useEffect(() => {
    if (listRef.current && orderDateOptions) {
      const list = listRef.current;
      const listWidth = list.getBoundingClientRect().width;
      const numberOfSlides = orderDateOptions.length;
      const listItemWidth = list.firstChild.getBoundingClientRect().width;
      totalSlideGroups.current = Math.ceil((listItemWidth * numberOfSlides) / listWidth);
    }
  }, [openTimeSelectionPanel && orderDateOptions]);

  useEffect(() => {
    if (
      window.location.href.includes("/order-confirmation") &&
      orderTimeContext &&
      orderTimeContext.value
    ) {
      generatedTimeOptions.current = false;
      localforage.getItem(skin + "__orderDate").then((storedOrderDate) => {
        setActiveOrderDateOption(storedOrderDate);
      });
    }
  }, [window.location.href, orderTimeContext]);

  let [originalOrderDate, setOriginalOrderDate] = useState(null);
  const routerLocation = useLocation();
  const [lastStoreDataCheckTimeStamp, setLastStoreDataCheckTimeStamp] = useState(null);

  const timeDifferentInMinutes = (date1, date2) => {
    const diff = Math.abs(new Date(date1) - new Date(date2));
    const minutes = Math.floor(diff / 1000 / 60);
    return minutes;
  };
  /** only call API 1300 and 1306 to re update the store data if there has been 5 minutes passed */
  useEffect(() => {
    if (
      (customClass !== "visually-hidden" &&
        lastStoreDataCheckTimeStamp !== null &&
        timeDifferentInMinutes(new Date(), lastStoreDataCheckTimeStamp) > 5) ||
      history.location.hash.includes("order-confirmation") ||
      sessionStorage.getItem(skin + "__requiresStoreAndTimeUpdate") !== null
    ) {
      if (
        history.location.hash.includes("order-confirmation") &&
        localStorage.getItem(skin + "__isComingFromMPI") === null
      ) {
        history.push("/dashboard");
      }
      localStorage.removeItem(skin + "__isComingFromMPI");
      sessionStorage.removeItem(skin + "__requiresStoreAndTimeUpdate");
      localforage.getItem(skin + "__activeOrderType").then((storeOrderType) => {
        if (storeOrderType) {
          orderTypeContext.update(storeOrderType);
          localforage.getItem(skin + "__activeOrderStore").then((storedLocation) => {
            if (storedLocation) {
              get1300Stores(skin, activeOrderType, appLanguage).then((allStores) => {
                if (allStores) {
                  allStores.forEach((store) => {
                    if (store.storeId === storedLocation.storeId) {
                      storeContext.updateActiveOrderStore(store);
                      setLastStoreDataCheckTimeStamp(new Date());
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  }, [lastStoreDataCheckTimeStamp]);

  useEffect(() => {
    if (
      customClass !== "visually-hidden" &&
      sessionStorage.getItem(skin + "__requiresStoreAndTimeUpdate") !== null
    ) {
      sessionStorage.removeItem(skin + "__requiresStoreAndTimeUpdate");
      localforage.getItem(skin + "__activeOrderType").then((storeOrderType) => {
        if (storeOrderType) {
          orderTypeContext.update(storeOrderType);
          localforage.getItem(skin + "__activeOrderStore").then((storedLocation) => {
            if (storedLocation) {
              get1300Stores(skin, activeOrderType, appLanguage).then((allStores) => {
                if (allStores) {
                  allStores.forEach((store) => {
                    if (store.storeId === storedLocation.storeId) {
                      storeContext.updateActiveOrderStore(store);
                      setLastStoreDataCheckTimeStamp(new Date());
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  }, [customClass]);

  useEffect(() => {
    if (routerLocation.state && routerLocation.state.showTimePanel) {
      setShowTimePanel(routerLocation.state.showTimePanel);
      document.getElementById("dateTimePanel").click();
      setOriginalOrderDate(routerLocation.state.timeAtOrdering);
    }

    const storedTimeStamp = sessionStorage.getItem(skin + "__lastStoreDataCheckTimeStamp");
    if (storedTimeStamp) {
      setLastStoreDataCheckTimeStamp(new Date(storedTimeStamp));
    } else {
      setLastStoreDataCheckTimeStamp(new Date());
    }
  }, []);

  useEffect(() => {
    if (lastStoreDataCheckTimeStamp) {
      sessionStorage.setItem(skin + "__lastStoreDataCheckTimeStamp", lastStoreDataCheckTimeStamp);
    }
  }, [lastStoreDataCheckTimeStamp]);

  /** reset some values when active Order Store is changed */
  useEffect(() => {
    if (activeOrderStore) {
      generatedTimeOptions.current = false;
      setOrderTimeToBeSelected(null);
    }
  }, [activeOrderStore]);

  useEffect(() => {
    if (showTimePanel) {
      setShowTimePanel(false);
      setOpenTimeSelectionPanel(true);
    } else {
      if (orderTimeContext && orderTimeContext.openOrderTimePopup) {
        setOpenTimeSelectionPanel(true);
      } else {
        setOpenTimeSelectionPanel(false);
      }
    }
  }, [orderTimeContext]);

  const updateActiveOrderDateIndex = (index, e) => {
    //e.target.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
    onOrderDateChange(
      orderDateOptions[index].value,
      orderDateOptions[index].displayValue,
      orderDateOptions[index].dateObject
    );

    setTimeout(() => {
      document.getElementById("order-settings-component__time-options-list").scrollTop = 0;
      if (
        document.getElementsByClassName("order-settings-component__time-options-selection-wrapper")
          .length > 0
      ) {
        document
          .getElementsByClassName("order-settings-component__time-options-selection-wrapper")[0]
          .scrollTo({
            top: 0,
            behavior: "smooth",
          });
      }
    }, 300);
  };

  const scrollDateListRight = () => {
    const list = listRef.current;
    const SCROLL_VALUE = list.offsetWidth - 50;
    list.style.transform = `translateX(${currentTransformX.current - SCROLL_VALUE}px)`;
    list.style.transition = `transform 500ms ease-in-out`;
    currentTransformX.current = currentTransformX.current - SCROLL_VALUE;

    if (Math.abs(currentTransformX.current) + SCROLL_VALUE >= list.scrollWidth) {
      setIsRightScrollPossible(false);
    }

    if (!isLeftScrollPossible) setIsLeftScrollPossible(true);
  };

  const scrollDateListLeft = () => {
    const list = listRef.current;
    const SCROLL_VALUE = list.offsetWidth - 50;
    list.style.transform = `translateX(${currentTransformX.current + SCROLL_VALUE}px)`;
    list.style.transition = `transform 500ms ease-in-out`;
    currentTransformX.current = currentTransformX.current + SCROLL_VALUE;

    if (currentTransformX.current >= 0) {
      setIsLeftScrollPossible(false);
    }

    if (!isRightScrollPossible) {
      setIsRightScrollPossible(true);
    }
  };

  const onOrderDateChange = (newValue, newDisplayValue, newDateObject) => {
    //localforage.removeItem(skin + "__orderTime"); // reset order time in storage
    generatedTimeOptions.current = false;
    const newActiveOption = { ...activeOrderDateOption };

    newActiveOption.value = newValue;
    newActiveOption.displayValue = newDisplayValue;
    newActiveOption.dateObject = newDateObject;

    //update the true business date attribute of order time context
    orderTimeContext.value.trueBusinessDate = newActiveOption.dateObject;

    //change the orderTimeContext value to the first available order time temporarily until user selects an order time
    const nextAvailableOrderTime = calculateNextAvailableOrderTime(
      activeOrderStore,
      activeOrderType,
      skin
    );
    // update below values only when not a case of originalOrderDate time panel is rendered
    if (!originalOrderDate) {
      orderTimeContext.value.value = nextAvailableOrderTime;
    }
    setActiveOrderDateOption(newActiveOption);
    setOrderTimeToBeSelected(null);

    //localforage.setItem(skin + "__orderDate", newActiveOption);
  };

  const onOrderTimeChange = (newValue, newDisplayValue) => {
    const newActiveOption = { ...activeOrderTimeOption };

    localforage.setItem(skin + "__trueBusinessDate", activeOrderDateOption.dateObject);
    newActiveOption.value = newValue === "Select Time" ? newValue : new Date(newValue);
    newActiveOption.displayValue = newDisplayValue;
    newActiveOption.openOrderTimePopup = false;
    newActiveOption.triggerMenu = true;
    newActiveOption.trueBusinessDate = getTrueBusinessDate(newActiveOption.value, activeOrderStore);

    setOrderTimeToBeSelected(newActiveOption);
  };

  const applySelectedOrderTime = () => {
    /** update the storage for date and time if a change is being applied */
    if (orderTimeToBeSelected) {
      setActiveOrderTimeOption(orderTimeToBeSelected);
      orderTimeToBeSelected.timeAtOrdering = originalOrderDate || new Date();
      orderTimeContext.update(orderTimeToBeSelected); // update order time in context
      const orderDate = new Date(orderTimeToBeSelected.value);
      const orderDateToStore = {
        value: orderDate.getMonth() + "-" + orderDate.getDate(), // the day of a date as a number (1-31)
        displayValue:
          orderDate.getDate() === new Date().getDate()
            ? appLabels["order"]["today"]
            : prettifyDate(orderDate, appLanguage), // stringified Date object
        dateObject: orderDate,
      };

      localforage.setItem(skin + "__orderDate", orderDateToStore);
      //setActiveOrderDateOption(orderDateToStore);
      initialTimeAndDate.current = null;
      //setOrderTimeToBeSelected(null);
    } else {
      orderTimeContext.value.timeAtOrdering = originalOrderDate || new Date();
      orderTimeContext.value.trueBusinessDate = initialTimeAndDate.current.date.dateObject;
      /** if no change is being applied, update the storage and states with initial values */
      orderContext.closeOrderTimePopupWidget(orderTimeContext);
      setActiveOrderDateOption(initialTimeAndDate.current.date);
      setActiveOrderTimeOption(initialTimeAndDate.current.time);
      initialTimeAndDate.current = null;
      generatedTimeOptions.current = false;
    }
  };

  const onOrderDateTimeConfirm = () => {
    applySelectedOrderTime();
    setOpenTimeSelectionPanel(false);
  };

  /** when the time option are rendered scroll them into view if possible */
  useEffect(() => {
    if (openTimeSelectionPanel && orderTimeOptions && orderTimeContext && orderTimeContext.value) {
      const matchingTime = orderTimeOptions.find(
        (tempTime) => tempTime.displayValue === orderTimeContext.value.displayValue
      );
      if (matchingTime) {
        const optionIndex = orderTimeOptions.indexOf(matchingTime);

        const listElement = document.getElementById(`time-option-${optionIndex - 1}`);
        if (listElement) {
          listElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
        }
      }
    }
  }, [openTimeSelectionPanel, orderTimeOptions, orderTimeContext]);

  /** when the date option are rendered scroll them into view if possible */
  useEffect(() => {
    if (openTimeSelectionPanel && orderDateOptions && orderTimeContext && orderTimeContext.value) {
      const matchingDate = orderDateOptions.find(
        (tempDate) =>
          tempDate.dateObject.getMonth() === orderTimeContext.value.value.getMonth() &&
          tempDate.dateObject.getDate() === orderTimeContext.value.value.getDate()
      );

      if (matchingDate) {
        const optionIndex = orderDateOptions.indexOf(matchingDate);
        const listElement = document.getElementById(`date-option-${optionIndex}`);
        if (listElement) {
          if (deviceWidth < 660) {
            /** mobile */
            setTimeout(() => {
              listElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
            }, 250);
          } else {
            /** Desktop */
            setTimeout(() => {
              listElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
              setTimeout(() => {
                if (document.getElementById("rightScrollButton")) {
                  document.getElementById("rightScrollButton").style.right =
                    "-" + listElement.parentElement.parentElement.parentElement.scrollLeft + "px";
                }

                if (document.getElementById("leftScrollButton")) {
                  document.getElementById("leftScrollButton").style.left =
                    listElement.parentElement.parentElement.parentElement.scrollLeft + "px";
                }
              }, 500);
            }, 250);
          }
        }
      }
    }
  }, [openTimeSelectionPanel, orderDateOptions, orderTimeContext]);

  useEffect(() => {
    if (activeOrderDateOption && activeOrderTimeOption && initialTimeAndDate.current === null) {
      initialTimeAndDate.current = { time: activeOrderTimeOption, date: activeOrderDateOption };
    }
  }, [activeOrderDateOption, activeOrderTimeOption, initialTimeAndDate]);

  const path = useWindowPath()[0];
  useEffect(() => {
    if (path && isOnlineOrderingEnabled) {
      orderContext.closeOrderTimePopupWidget(orderTimeContext);
    }
  }, [path]);

  const [bottomValue, setBottomValue] = useState(null);
  useEffect(() => {
    if (deviceWidth < 600) {
      if (
        document.getElementsByClassName("footer").length > 0 &&
        window.innerHeight -
          document.getElementsByClassName("footer")[0].getBoundingClientRect().top >=
          56
      ) {
        setBottomValue(
          window.innerHeight -
            2 -
            document.getElementsByClassName("footer")[0].getBoundingClientRect().top +
            "px"
        );
      } else {
        setBottomValue(56);
      }
    }
  });

  return (
    <>
      {isOnlineOrderingEnabled && (
        <>
          {((deviceWidth < 660 && bottomValue !== null) || deviceWidth >= 660) && (
            <div
              className={`order-settings-component__main-container ${
                customClass ? customClass : ""
              } ${isTermsEnabled ? "terms-enabled" : " no-terms-enabled"}`}
              style={deviceWidth < 660 ? { bottom: bottomValue } : {}}
            >
              <div className="order-settings-component__main-wrapper">
                {deviceWidth < 660 ? (
                  <>
                    <div className="order-settings-component__row">
                      {activeOrderType && activeOrderStore && (
                        <div
                          className="order-settings-component__column"
                          style={
                            activeOrderType === "dinein" && !hideOrderModule
                              ? { maxWidth: "85%", width: "85%" }
                              : activeOrderType === "dinein" && hideOrderModule
                              ? { maxWidth: "100%", width: "100%" }
                              : {}
                          }
                        >
                          <Link
                            className="order-settings-component__order-type"
                            style={{ color: textIconColor }}
                            to="/online-ordering/order-type"
                          >
                            {activeOrderType === "dinein" ? (
                              <>
                                {getTranslatedOrderType(activeOrderType, appLabels)} {"|"}{" "}
                                {capitalizeWords(appLabels["order"]["table"])} {activeTableNumber}
                              </>
                            ) : isStadiumSchema && activeOrderType === "delivery" ? (
                              appLabels["order"]["in-seat-service"]
                            ) : (
                              <>
                                {getTranslatedOrderType(activeOrderType, appLabels)}
                                {
                                  appLabels["order"]["item-for-pickup-at"].split([
                                    "[order-type]",
                                  ])[1]
                                }
                              </>
                            )}
                          </Link>

                          {activeOrderStore &&
                            activeOrderStore.vexilorConfig &&
                            activeOrderType !== "dinein" && (
                              <OrderSettingsTime
                                setOpenTimeSelectionPanel={setOpenTimeSelectionPanel}
                                openTimeSelectionPanel={openTimeSelectionPanel}
                                setOrderDateOptions={setOrderDateOptions}
                                setActiveOrderDateOption={setActiveOrderDateOption}
                                setOrderTimeOptions={setOrderTimeOptions}
                                setActiveOrderTimeOption={setActiveOrderTimeOption}
                                activeOrderDateOption={activeOrderDateOption}
                                generatedTimeOptions={generatedTimeOptions}
                              />
                            )}

                          {activeOrderType === "dinein" && <OrderSettingsStore />}
                        </div>
                      )}

                      {activeOrderType !== "dinein" && (
                        <div
                          className={`order-settings-component__column ${
                            !activeOrderStore ? "select-store" : ""
                          }`}
                        >
                          {activeOrderStore && (
                            <div className="order-settings-component__row">
                              <Link
                                className="order-settings-component__order-type"
                                style={{ color: textIconColor }}
                                to="/online-ordering/order-type"
                              >
                                {appLabels["order"]["ordering-from"]}
                              </Link>
                            </div>
                          )}

                          <div className="order-settings-component__row">
                            <OrderSettingsStore />
                          </div>
                        </div>
                      )}

                      {!hideOrderModule && (
                        <div className="order-settings-component__column cart-button">
                          <div className="order-settings-component__row">
                            <OrderSettingsCart />
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    {deviceWidth <= 1024 ? (
                      <div className="order-settings-component__row">
                        {activeOrderType && activeOrderStore && (
                          <div
                            className="order-settings-component__column"
                            style={
                              activeOrderType === "dinein"
                                ? { maxWidth: "85%", width: "85%" }
                                : { width: "45%" }
                            }
                          >
                            <div className="order-settings-component__row">
                              <Link
                                className="order-settings-component__order-type"
                                style={{ color: textIconColor }}
                                to="/online-ordering/order-type"
                              >
                                {activeOrderType === "dinein" ? (
                                  <>
                                    {getTranslatedOrderType(activeOrderType, appLabels)} {"|"}{" "}
                                    {capitalizeWords(appLabels["order"]["table"])}{" "}
                                    {activeTableNumber}
                                  </>
                                ) : isStadiumSchema && activeOrderType === "delivery" ? (
                                  appLabels["order"]["in-seat-service"]
                                ) : (
                                  <>
                                    {getTranslatedOrderType(activeOrderType, appLabels)}
                                    {
                                      appLabels["order"]["item-for-pickup-at"].split([
                                        "[order-type]",
                                      ])[1]
                                    }
                                  </>
                                )}
                              </Link>
                            </div>

                            <div className="order-settings-component__row">
                              {activeOrderStore &&
                                activeOrderStore.vexilorConfig &&
                                activeOrderType !== "dinein" && (
                                  <OrderSettingsTime
                                    setOpenTimeSelectionPanel={setOpenTimeSelectionPanel}
                                    openTimeSelectionPanel={openTimeSelectionPanel}
                                    setOrderDateOptions={setOrderDateOptions}
                                    setActiveOrderDateOption={setActiveOrderDateOption}
                                    setOrderTimeOptions={setOrderTimeOptions}
                                    setActiveOrderTimeOption={setActiveOrderTimeOption}
                                    activeOrderDateOption={activeOrderDateOption}
                                    generatedTimeOptions={generatedTimeOptions}
                                  />
                                )}

                              {activeOrderType === "dinein" && <OrderSettingsStore />}
                            </div>
                          </div>
                        )}

                        {activeOrderType !== "dinein" && (
                          <div
                            className={`order-settings-component__column ${
                              !activeOrderStore ? "select-store" : ""
                            }`}
                            style={{ width: "45%" }}
                          >
                            {activeOrderStore && (
                              <div className="order-settings-component__row">
                                <Link
                                  className="order-settings-component__order-type"
                                  style={{ color: textIconColor }}
                                  to="/online-ordering/order-type"
                                >
                                  {appLabels["order"]["ordering-from"]}
                                </Link>
                              </div>
                            )}

                            <div className="order-settings-component__row">
                              <OrderSettingsStore />
                            </div>
                          </div>
                        )}

                        {!hideOrderModule && (
                          <div className="order-settings-component__column cart-button">
                            <div className="order-settings-component__row">
                              <OrderSettingsCart />
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="order-settings-component__row">
                        {activeOrderType && activeOrderStore && (
                          <div className="order-settings-component__column order-type_component">
                            <Link
                              className="order-settings-component__order-type"
                              style={{ color: textIconColor }}
                              to="/online-ordering/order-type"
                            >
                              {activeOrderType === "dinein" ? (
                                <>
                                  {getTranslatedOrderType(activeOrderType, appLabels)} {"|"}{" "}
                                  {capitalizeWords(appLabels["order"]["table"])} {activeTableNumber}
                                </>
                              ) : isStadiumSchema && activeOrderType === "delivery" ? (
                                appLabels["order"]["in-seat-service"]
                              ) : (
                                <>
                                  {getTranslatedOrderType(activeOrderType, appLabels)}
                                  {
                                    appLabels["order"]["item-for-pickup-at"].split([
                                      "[order-type]",
                                    ])[1]
                                  }
                                </>
                              )}
                            </Link>
                          </div>
                        )}

                        {activeOrderStore &&
                          activeOrderStore.vexilorConfig &&
                          activeOrderType !== "dinein" && (
                            <div className="order-settings-component__column order-time_component">
                              <OrderSettingsTime
                                setOpenTimeSelectionPanel={setOpenTimeSelectionPanel}
                                openTimeSelectionPanel={openTimeSelectionPanel}
                                setOrderDateOptions={setOrderDateOptions}
                                setActiveOrderDateOption={setActiveOrderDateOption}
                                setOrderTimeOptions={setOrderTimeOptions}
                                setActiveOrderTimeOption={setActiveOrderTimeOption}
                                activeOrderDateOption={activeOrderDateOption}
                                generatedTimeOptions={generatedTimeOptions}
                              />
                            </div>
                          )}

                        <div className="order-settings-component__column order-type_component">
                          <Link
                            className="order-settings-component__order-type"
                            style={{ color: textIconColor }}
                            to="/online-ordering/order-type"
                          >
                            {appLabels["order"]["ordering-from"]}
                          </Link>
                        </div>

                        <div className="order-settings-component__column store_component">
                          <OrderSettingsStore />
                        </div>

                        {activeOrderStore && activeOrderType && activeOrderDateOption && (
                          <div
                            className="order-settings-component__column order-button_component"
                            style={
                              window.location.href.includes("dashboard")
                                ? { visibility: "visible", width: "unset !important" }
                                : { visibility: "hidden" }
                            }
                          >
                            <button
                              type="button"
                              className="button--primary button--primary-with-border button--primary-small button"
                              style={{
                                color: textIconColor,
                                backgroundColor:
                                  branding["online-order-settings-background-colour"],
                                borderColor: textIconColor,
                              }}
                              onClick={() => {
                                orderContext.checkOrderSettings(
                                  activeOrderType,
                                  storeContext,
                                  orderTimeContext
                                );
                              }}
                            >
                              {appLabels["general"]["order"]}{" "}
                            </button>
                          </div>
                        )}

                        {!hideOrderModule && (
                          <div className="order-settings-component__column cart_component">
                            <OrderSettingsCart />
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}

      {openTimeSelectionPanel && (
        <div className="order-settings-component__time-selection-main-container">
          <div className="order-settings-component__time-selection-inner-wrapper">
            <div className="order-settings-component__time-selection-header-wrapper">
              <span>
                {isStadiumSchema && activeOrderType === "delivery" ? (
                  <>{appLabels["order"]["in-seat-service-time-title"]}</>
                ) : isStadiumSchema && activeOrderType === "pickup" ? (
                  <>{appLabels["order"]["stadium-pickup-time-title"]}</>
                ) : (
                  <>
                    {appLabels["order"]["time-selection-panel-header"].replace(
                      "[order-type]",
                      getTranslatedOrderType(activeOrderType, appLabels)
                    )}
                  </>
                )}
              </span>
            </div>

            <div className="order-settings-component__time-date-selection-wrapper">
              {orderDateOptions && orderTimeContext && orderTimeContext.value && (
                <>
                  <div className="order-settings-component__date-list-container">
                    {!isTouchDevice() && isLeftScrollPossible && deviceWidth >= 660 && (
                      <SliderScrollButton
                        handleClick={scrollDateListLeft}
                        actionDescription="Scroll to the previous group of dates"
                        icon={<IconArrowLeft aria-hidden="true" style={{ width: "30px" }} />}
                        direction="previous"
                        id="leftScrollButton"
                      />
                    )}

                    <ol
                      className="order-settings-component__date-list"
                      id="order-settings-component__date-list"
                      ref={listRef}
                    >
                      {orderDateOptions.map((dateOption, index) => (
                        <React.Fragment key={index}>
                          <li>
                            <button
                              style={
                                orderTimeContext &&
                                dateOption.dateObject.getDate() ===
                                  orderTimeContext.value.trueBusinessDate.getDate() &&
                                dateOption.dateObject.getMonth() ===
                                  orderTimeContext.value.trueBusinessDate.getMonth()
                                  ? { color: `var(--color-online-order-settings-active-color)` }
                                  : { color: textIconColor }
                              }
                              className={` date-button ${
                                orderTimeContext &&
                                dateOption.dateObject.getDate() ===
                                  orderTimeContext.value.trueBusinessDate.getDate() &&
                                dateOption.dateObject.getMonth() ===
                                  orderTimeContext.value.trueBusinessDate.getMonth()
                                  ? " active-date-button"
                                  : ""
                              }`}
                              id={`date-option-${index}`}
                              onClick={(e) => {
                                updateActiveOrderDateIndex(index, e);
                              }}
                            >
                              <span className="month-span">
                                {getMonthFromIndex(dateOption.dateObject.getMonth(), appLabels)
                                  .substr(0, 3)
                                  .toUpperCase()}
                              </span>
                              <span className="date-span">{dateOption.dateObject.getDate()}</span>
                              <span className="day-span">
                                {dateOption.dateObject.getDay() === new Date().getDay() &&
                                dateOption.dateObject.getDate() === new Date().getDate() &&
                                dateOption.dateObject.getMonth() === new Date().getMonth()
                                  ? appLabels["order"]["today"]
                                  : capitalizeWords(
                                      getDayOfWeekFromIndex(
                                        dateOption.dateObject.getDay(),
                                        appLabels
                                      ).substr(0, 3)
                                    )}
                              </span>
                            </button>
                          </li>
                        </React.Fragment>
                      ))}
                    </ol>

                    {!isTouchDevice() &&
                      listRef.current &&
                      isRightScrollPossible &&
                      deviceWidth >= 660 && (
                        <SliderScrollButton
                          handleClick={scrollDateListRight}
                          actionDescription="Scroll to the next group of dates"
                          icon={<IconArrowRight aria-hidden="true" />}
                          direction="next"
                          id="rightScrollButton"
                        />
                      )}
                  </div>
                </>
              )}
            </div>

            <div className="order-settings-component__time-options-selection-wrapper">
              <ol id="order-settings-component__time-options-list">
                {orderTimeOptions &&
                  orderTimeOptions.map((timeOption, index) => (
                    <React.Fragment key={index}>
                      <li className="form__field-wrapper form__field-wrapper--checkbox-radio form__field-wrapper--radio">
                        <input
                          type="radio"
                          name="dashboard-order-time-selection"
                          value={timeOption.value}
                          id={`time-option-${index}`}
                          checked={
                            orderTimeToBeSelected
                              ? JSON.stringify(orderTimeToBeSelected.displayValue) ===
                                JSON.stringify(timeOption.displayValue)
                              : orderTimeContext.value
                              ? JSON.stringify(orderTimeContext.value.displayValue) ===
                                  JSON.stringify(timeOption.displayValue) &&
                                orderTimeContext.value.value.getDate() ===
                                  timeOption.value.getDate()
                              : false
                          }
                          onChange={() => {
                            onOrderTimeChange(timeOption.value, timeOption.displayValue);
                          }}
                          className="form__field--checkbox-radio form__field--radio visually-hidden"
                        />

                        <label
                          htmlFor={`time-option-${index}`}
                          className="form__field-label--checkbox-radio form__field-label--radio"
                        >
                          <span>{timeOption.displayValue} </span>
                          <span className="time-option__day-after-label">
                            {timeOption.value.getDate() >
                              orderTimeContext.value.trueBusinessDate.getDate() ||
                            timeOption.value.getMonth() >
                              orderTimeContext.value.trueBusinessDate.getMonth()
                              ? `(${prettifyDate(timeOption.value)})`
                              : ""}
                          </span>
                        </label>
                      </li>
                    </React.Fragment>
                  ))}
              </ol>
            </div>

            <div className="order-settings-component__time-options-confirm-button-wrapper">
              <button
                type="button"
                onClick={onOrderDateTimeConfirm}
                className="button button--primary"
              >
                {appLabels["general"]["done"]}
              </button>
            </div>
          </div>
        </div>
      )}

      {/*storeIsClosed && (
        <DialogModal
          message={appLabels["order"]["order-before-store-opening-time-error"]}
          resetRemoveDialog={() => {
            setStoreIsClosed(false);
          }}
        />
        )*/}
    </>
  );
};
export default OrderSettingsComponent;

const SliderScrollButton = ({ handleClick, actionDescription, icon, direction, id }) => {
  const ARROW_BUTTON_WIDTH = 50;
  return (
    <button
      onClick={handleClick}
      className={`order-settings-dates__horizontal-slider-button order-settings-dates__horizontal-slider-${direction}-button`}
      style={{ width: `${ARROW_BUTTON_WIDTH}px` }}
      type="button"
      id={id}
    >
      <span className="visually-hidden">{actionDescription}</span>
      {icon}
    </button>
  );
};
