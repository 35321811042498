// Libraries
import React, { useContext, useState, useEffect } from "react";

// Custom hooks
import { useModal } from "../../_common/Modal/useModal";
import useWindowSize from "../../_common/hooks/useWindowSize";

// Helper functions
import { toDollars, get } from "../../_common/helpers";
import { getCartSubtotal, formatCartForApi } from "../../_common/CartHelpers";
import { formatBillItemsForApi } from "../Menu/Bill/BillHelpers";

// Contexts
import AppSettingsContext from "../../App/AppSettingsContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import UserRoleContext from "../../App/UserRoleContext";
import StoreContext from "../StoreContext";
import CWS5ModuleContext from "../../App/CWS5ModuleContext";

// UI components
import PromoCouponForm from "./PromoCouponForm/PromoCouponForm";
import RewardsForm from "./RewardsForm/RewardsForm";
import CartIndicator from "../../_common/CartIndicator/CartIndicator";
import { Modal } from "../../_common/Modal/Modal";
import { DialogModal } from "../../_common/DialogModal/DialogModal";
import { LoadingSpinner } from "../../_common/LoadingSpinner";
import IndividualLoginButton from "./IndividualLoginButton";

// CSS
import "./CartSummary.css";

export const CartSummary = (props) => {
  const {
    setShowCheckoutButton,
    items,
    storedRewards,
    currentLocation,
    updateOrderSummary,
    storedPromoCode,
    setStoredPromoCode,
    setCoupon,
    coupon,
    setStoredRewards,
    isAnimateIcon,
    orderSummaryData,
    isTaxIncluded,
    setStoredRewardsDetails,
    storedRewardsDetails,
    setOrderSummaryData,
    isCollapsed,
  } = props;

  const merchantConfigContext = useContext(MerchantConfigContext);
  const skin = merchantConfigContext.skin;
  const vexilorConfig = merchantConfigContext.vexilor;
  const activeOrderStore = useContext(StoreContext).activeOrderStore;
  const cws5ModuleContext = useContext(CWS5ModuleContext);

  const deviceWidth = useWindowSize().width;

  const { isModal, toggleModal, setIsModal, closeAnimation } = useModal();

  const [modalDescription, setModalDescription] = useState("");
  const [modalHeading, setModalHeading] = useState("");

  const isLoyaltyEnabled = cws5ModuleContext.isLoyaltyEnabled;

  const userRoleContext = useContext(UserRoleContext);
  const loginToken = userRoleContext.loginToken;

  const closeModal = () => {
    setIsModal(false);
    if (setShowCheckoutButton) {
      setShowCheckoutButton(true);
    }
  };

  const [formattedCart, setFormattedCart] = useState(null);
  useEffect(() => {
    if (items) {
      const tempFormattedCart = window.location.href.includes("bill-payment")
        ? formatBillItemsForApi(items)
        : formatCartForApi(items);
      if (formattedCart !== tempFormattedCart) setFormattedCart(tempFormattedCart);
    }
  }, [items]);

  const [isErrorNotification, setIsErrorNotification] = useState(false);
  const [errorNotificationText, setErrorNotificationText] = useState("");

  const appLabels = useContext(AppLabelsContext);

  const [showRewards, setShowRewards] = useState(false);
  const openPromoModal = () => {
    if (storedRewards && !!storedRewards.length) {
      setErrorNotificationText(appLabels["order"]["only-one-discount-reward-error"]);
      setIsErrorNotification(true);
    } else {
      setShowRewards(false);
      setModalDescription("");
      setModalHeading("");
      toggleModal();
    }
  };

  const openRewardsModal = () => {
    if (storedPromoCode || coupon) {
      setErrorNotificationText(appLabels["order"]["only-one-discount-reward-error"]);
      setIsErrorNotification(true);
    } else {
      setShowRewards(true);
      setModalDescription(appLabels["order"]["use-rewards"]);
      setModalHeading(appLabels["order"]["use-rewards"]);
      toggleModal();
      if (setShowCheckoutButton) {
        setShowCheckoutButton(false);
      }
    }
  };

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);

  const [quantity, setQuantity] = useState(0);
  useEffect(() => {
    if (items) {
      let counter = 0;
      items.forEach((item) => {
        if (item.quantity) {
          counter += item.quantity;
        } else {
          counter++;
        }
      });

      setQuantity(counter);
    }
  }, [items]);

  const isOnBillPaymentPage = window.location.href.includes("bill-payment");

  return (
    <>
      <div
        className={`cart-summary__container  ${
          isCollapsed
            ? " collapsable-section__collapsed-content"
            : " collapsable-section__expanded-content"
        }`}
      >
        {!isOnBillPaymentPage && (
          <div className="cart-summary__cart-column">
            {userRoleContext.status === "guest" && (
              <IndividualLoginButton floatRight={deviceWidth >= 850 ? true : false} />
            )}

            <button
              className="button button--white button--pill-shaped button--add-promo button--primary button--primary-with-border button--primary-small"
              onClick={openPromoModal}
              type="button"
            >
              {appLabels["order"]["discount-and-coupon-button"]}
            </button>
            {loginToken && isLoyaltyEnabled && (
              <button
                className="button button--white button--pill-shaped button--use-rewards button--primary button--primary-small button--primary-with-border"
                onClick={openRewardsModal}
                type="button"
              >
                {appLabels["order"]["use-rewards"]}
              </button>
            )}
          </div>
        )}

        <div
          className="cart-summary__totals-column"
          style={isOnBillPaymentPage ? { width: "100%" } : {}}
        >
          <div className="cart-indicator__wrapper">
            {!!items && (
              <CartIndicator
                quantity={quantity}
                isAnimated={isAnimateIcon}
                activeOrderStore={activeOrderStore}
              />
            )}
            <span>{appLabels["order"]["items-in-the-cart"]}</span>
          </div>
          {!!orderSummaryData && orderSummaryData !== "reset" && (
            <div className="cart-summary__total-wrapper">
              <div className="cart-summary__line">
                <span className="cart-summary__label">
                  {appLabels["general"]["subtotal"] + ":"}
                </span>
                <span className="cart-summary__output" aria-live="assertive">
                  {toDollars(
                    appSettings["currency-symbol"],
                    appSettings["currency-symbol-side"],
                    orderSummaryData.orderSubtotal
                      ? orderSummaryData.orderSubtotal
                      : getCartSubtotal(orderSummaryData),
                    appLanguage
                  )}
                </span>
              </div>
              {!!orderSummaryData.serviceChargeAmount &&
                !isNaN(orderSummaryData.serviceChargeAmount) && (
                  <div className="cart-summary__line cart-summary__line-service-charge">
                    <span className="cart-summary__label cart-summary__label-service-charge-label">
                      {orderSummaryData.serviceChargeDesc + ": "}
                    </span>
                    <span className="cart-summary__output" aria-live="assertive">
                      {toDollars(
                        appSettings["currency-symbol"],
                        appSettings["currency-symbol-side"],
                        orderSummaryData.serviceChargeAmount,
                        appLanguage
                      )}
                    </span>
                  </div>
                )}
              {!!orderSummaryData.discountTotal && (
                <div className="cart-summary__line">
                  <span className="cart-summary__label">
                    {appLabels["order"]["discount"] + ": "}
                  </span>
                  <span
                    className="cart-summary__output cart-summary__discount-value"
                    aria-live="assertive"
                  >
                    {`- ${toDollars(
                      appSettings["currency-symbol"],
                      appSettings["currency-symbol-side"],
                      orderSummaryData.discountTotal,
                      appLanguage
                    )}`}
                  </span>
                </div>
              )}
              {!!orderSummaryData.taxTotal && !isTaxIncluded && (
                <div className="cart-summary__line">
                  <span className="cart-summary__label">{appLabels["order"]["tax"] + ": "}</span>
                  <span className="cart-summary__output" aria-live="assertive">
                    {toDollars(
                      appSettings["currency-symbol"],
                      appSettings["currency-symbol-side"],
                      orderSummaryData.taxTotal,
                      appLanguage
                    )}
                  </span>
                </div>
              )}
              {!!orderSummaryData.tipAmount && (
                <div className="cart-summary__line">
                  <span className="cart-summary__label">{appLabels["order"]["tip"] + ": "}</span>
                  <span className="cart-summary__output" aria-live="assertive">
                    {toDollars(
                      appSettings["currency-symbol"],
                      appSettings["currency-symbol-side"],
                      orderSummaryData.tipAmount,
                      appLanguage
                    )}
                  </span>
                </div>
              )}
              {!!get(props, "coupon.balance") && (
                <div className="cart-summary__line cart-summary__line--coupon">
                  <span className="cart-summary__label">{appLabels["order"]["coupon"] + ": "}</span>
                  <span
                    className="cart-summary__output cart-summary__discount-value"
                    aria-live="assertive"
                  >
                    {`- ${toDollars(
                      appSettings["currency-symbol"],
                      appSettings["currency-symbol-side"],
                      coupon.balance,
                      appLanguage
                    )}`}
                  </span>
                </div>
              )}
              {!isNaN(get(props, "orderSummaryData.orderTotal")) && (
                <div className="cart-summary__line cart-summary__line--total">
                  <span className="cart-summary__label">{appLabels["general"]["total"] + ":"}</span>
                  <span className="cart-summary__output" aria-live="assertive">
                    {!!orderSummaryData.tipAmount
                      ? toDollars(
                          appSettings["currency-symbol"],
                          appSettings["currency-symbol-side"],
                          orderSummaryData.orderTotal + Number(orderSummaryData.tipAmount),
                          appLanguage
                        )
                      : toDollars(
                          appSettings["currency-symbol"],
                          appSettings["currency-symbol-side"],
                          orderSummaryData.orderTotal,
                          appLanguage
                        )}
                  </span>
                </div>
              )}
            </div>
          )}
          {(!orderSummaryData || orderSummaryData === "reset") && !showRewards && (
            <LoadingSpinner isAbsolute={true} />
          )}
        </div>
      </div>
      <Modal
        isModal={isModal}
        heading={modalHeading}
        toggleModal={() => {
          toggleModal();
          if (setShowCheckoutButton) {
            setShowCheckoutButton(true);
          }
        }}
        content={
          showRewards ? (
            <RewardsForm
              skin={skin}
              formattedCart={formattedCart}
              currentLocation={currentLocation}
              updateOrderSummary={updateOrderSummary}
              closeModal={closeModal}
              storedPromoCode={storedPromoCode}
              storedRewards={storedRewards}
              setStoredRewards={setStoredRewards}
              setStoredRewardsDetails={setStoredRewardsDetails}
              storedRewardsDetails={storedRewardsDetails}
            />
          ) : (
            <PromoCouponForm
              skin={skin}
              formattedCart={formattedCart}
              currentLocation={currentLocation}
              updateOrderSummary={updateOrderSummary}
              storedPromoCode={storedPromoCode}
              setStoredPromoCode={setStoredPromoCode}
              setCoupon={setCoupon}
              closeModal={closeModal}
              storedRewards={storedRewards}
              isCouponEnabled={!!vexilorConfig.I2.payment_methods.COUPON}
              storedCoupons={coupon}
              setOrderSummaryData={setOrderSummaryData}
            />
          )
        }
        isClickAway={true}
        wrapperClass={`modal-wrapper--apply-discounts modal--coupon-discount${
          showRewards ? " modal-wrapper--rewards-form" : ""
        }`}
        type="fullscreen"
        description={modalDescription}
        isTransparent={modalHeading !== appLabels["order"]["use-rewards"]}
        isCloseButton={true}
        closeAnimation={closeAnimation}
      />
      {isErrorNotification && (
        <DialogModal
          message={errorNotificationText}
          resetRemoveDialog={() => setIsErrorNotification(false)}
        />
      )}
    </>
  );
};
