import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import localforage from "localforage";

/** Hooks */
import useWindowSize from "../../_common/hooks/useWindowSize";

/** Contexts */
import MerchantConfigContext from "../MerchantConfigContext";

/** UI Components */
import { AppDesktopHeaderSubmenu } from "./AppDesktopHeaderSubmenu";
import { AppDesktopHeaderMegaSubmenu } from "./AppDesktopHeaderMegaSubmenu";

/** UI Assets */
import { ReactComponent as IconArrowDown } from "../../_common/icons/IconArrowDown.svg";

export const AppDesktopHeaderNavItem = ({ module, noAdditionalLanguage }) => {
  const history = useHistory();
  const pathname = history.location.pathname;

  const [currentModule, setCurrentModule] = useState("");
  useEffect(() => {
    const isDashboard = pathname.includes("/dashboard");

    const isOrderFlow = pathname.includes("/online-ordering");
    const isLoyaltyFlow = pathname.includes("rewards");
    const isGiftCardsFlow = pathname.includes("/gift-card");
    const isAccountFlow = pathname.includes("/account");

    if (isOrderFlow) setCurrentModule("order");
    else if (isLoyaltyFlow) setCurrentModule("loyalty");
    else if (isGiftCardsFlow) setCurrentModule("gift-card");
    else if (isAccountFlow) setCurrentModule("account");
    else if (isDashboard) setCurrentModule("");
  }, [pathname]);

  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const deviceWidth = useWindowSize().width;

  const linkRef = useRef();
  useEffect(() => {
    if (
      deviceWidth > 1125 &&
      linkRef.current &&
      (module.subnavItems || module.name === "account" || module.name === "language")
    ) {
      linkRef.current.addEventListener("mouseenter", handleMouseEnter);
      linkRef.current.addEventListener("mouseleave", handleMouseLeave);

      return () => {
        if (linkRef && linkRef.current) {
          linkRef.current.removeEventListener("mouseenter", handleMouseEnter);
          linkRef.current.removeEventListener("mouseleave", handleMouseLeave);
        }
      };
    }
  }, [linkRef.current, deviceWidth]);

  function handleMouseEnter(event) {
    const listItem = event.target;

    listItem.querySelectorAll("[aria-expanded='false']").forEach((element) => {
      element.setAttribute("aria-expanded", "true");
    });
  }

  function handleMouseLeave(event) {
    const listItem = event.target;

    listItem.querySelectorAll("[aria-expanded='true']").forEach((element) => {
      element.setAttribute("aria-expanded", "false");
    });
  }

  const activeNavItemClass =
    currentModule === module.name ? " desktop-header__nav-item--active" : "";

  return (
    <li
      ref={linkRef}
      className={`desktop-header__nav-item${activeNavItemClass}`}
      data-item={module.name}
      style={
        noAdditionalLanguage && (module.name === "sign-in" || module.name === "account")
          ? { marginLeft: "auto" }
          : {}
      }
    >
      {module.action && <NavItemButton module={module} isSubmenuOpen={isSubmenuOpen} />}
      {module.linkPathname && <NavItemLink module={module} />}
      {!module.action && !module.linkPathname && (
        // Non-clickable top-level item
        <div className="desktop-header__nav-link">{module.displayName}</div>
      )}
      {(!!module.subnavItems || module.name === "account") && (
        <ItemDropdownButton
          module={module}
          isSubmenuOpen={isSubmenuOpen}
          setIsSubmenuOpen={setIsSubmenuOpen}
        />
      )}
      {!!module.subnavItems && <AppDesktopHeaderSubmenu subnavItems={module.subnavItems} />}
      {module.name === "account" && <AppDesktopHeaderMegaSubmenu />}
    </li>
  );
};

const NavItemButton = ({ module, isSubmenuOpen }) => {
  return (
    <button
      onClick={module.action}
      aria-haspopup={!!module.subnavItems}
      aria-expanded={isSubmenuOpen}
      className="desktop-header__nav-button"
      type="button"
    >
      {module.displayName}
    </button>
  );
};

const NavItemLink = ({ module }) => {
  const { skin } = useContext(MerchantConfigContext);
  const handleClick = () => {
    localforage.removeItem(skin + "__itemSelectionInProgress");
    localforage.removeItem(skin + "__storeIdToBeFaved");
    sessionStorage.removeItem(skin + "__orderStoreState");
  };

  return (
    <>
      {module.isExternalLink ? (
        <a
          href={module.linkPathname}
          className="desktop-header__nav-link"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClick}
        >
          {module.displayName}
        </a>
      ) : (
        <Link to={module.linkPathname} className="desktop-header__nav-link" onClick={handleClick}>
          {module.displayName}
        </Link>
      )}
    </>
  );
};

const ItemDropdownButton = (props) => {
  const { isSubmenuOpen, setIsSubmenuOpen, module } = props;

  const deviceWidth = useWindowSize().width;

  const showSubmenuButtonRef = useRef();

  useEffect(() => {
    if (showSubmenuButtonRef.current) {
      showSubmenuButtonRef.current.addEventListener("keydown", handleKeydown);

      if (deviceWidth < 1125) {
        showSubmenuButtonRef.current.addEventListener("click", handleClick);
      }

      return () => {
        if (showSubmenuButtonRef && showSubmenuButtonRef.current) {
          showSubmenuButtonRef.current.removeEventListener("keydown", handleKeydown);

          if (deviceWidth < 1125) {
            showSubmenuButtonRef.current.removeEventListener("click", handleClick);
          }
        }
      };
    }
  }, [showSubmenuButtonRef.current, isSubmenuOpen, deviceWidth]);

  function handleKeydown(event) {
    const isEnterKey = event.code === "Enter" || event.code === "NumpadEnter";
    const isSpaceKey = event.code === "Space";

    if (isSpaceKey) {
      // prevent page scroll trigger
      event.preventDefault();
    }

    if (isEnterKey || isSpaceKey) setIsSubmenuOpen(!isSubmenuOpen);
  }

  function handleClick() {
    setIsSubmenuOpen(!isSubmenuOpen);
  }

  const buttonText = `${isSubmenuOpen ? "Hide" : "Show"} ${module.name} submenu`;

  return (
    <button
      ref={showSubmenuButtonRef}
      aria-expanded={isSubmenuOpen}
      className="desktop-header-nav-item__show-submenu-button"
      type="button"
    >
      <IconArrowDown aria-hidden="true" />
      <span className="visually-hidden">{buttonText}</span>
    </button>
  );
};
