export const formLabels = {
  "card-details": "Détails de la carte",
  "credit-card-number": "Numéro de carte de crédit",
  "name-on-card": "Nom du détenteur",
  "expiry-date": "Date d'expiration",
  cvv: "CVV",
  "save-credit-card": "Sauvegarder la carte de crédit",
  "billing-address": "Adresse de facturation",
  "address-1": "Adresse 1",
  "address-2": "Adresse 2",
  country: "Pays",
  province: "Province",
  city: "Ville",
  postal: "Code postal",
  phone: "Téléphone",
  email: "Courriel",
  "confirm-email": "Confirmer le courriel",
  password: "Mot de passe",
  "confirm-password": "Confirmer le mot de passe",
  "keep-me-logged-in": "Se souvenir de moi",
  "first-name": "Prénom",
  "last-name": "Nom de famille",
  "special-instructions": "Instructions spéciales",
  "form-security-message": "Ces informations seront sauvegardées et cryptées",
  "card-number": "Numéro de carte",
  "card-description": "Description de la carte",
  required: "Requis",
  "missing-required-fields-error": "Veuillez remplir les champs ci-dessus",
  "missing-required-addons-modifiers-error": "Des choix obligatoires sont manquants",
  "title-numbers-error": "Le titre ne peut contenir de chiffres",
  "first-name-numbers-error": "Le prénom ne peut contenir de chiffres",
  "middle-name-numbers-error": "Le deuxième prénom ne peut contenir de chiffres",
  "last-name-numbers-error": "Le nom de famille ne peut contenir de chiffres",
  "invalid-email-error": "Veullez saisir une adresse de courriel",
  "phone-only-numbers-error": "Le numéro de téléphone ne peut contenir que des chiffres",
  "invalid-card-number": "Veuillez saisir une numéro correct",
  "credit-card-numbers-error": "Le nom du détenteur ne peut contenir de chiffres",
  "invalid-date-error": "Veuillez saisir une date correcte",
  "invalid-cvv-error": "Veuillez saisir un CVV correct",
  "invalid-postal-error": "Veuillez saisir un code postal correct",
  "invalid-birthday-error": "Veuillez saisir une date d'anniversaire correcte",
  "password-char-length-error": "Veuillez saisir au moins 8 caractères",
  "passwords-dont-match": "Les mots de passe ne correspondent pas",
  "emails-dont-match": "Les courriels ne correspondent pas",
  "city-numbers-error": "La ville ne peut contenir de chiffres",
  "country-numbers-error": "Le pays ne peut contenir de chiffres",
  "county-numbers-error": "Le comté ne peut contenir de chiffres",
  "select-one": "Choisir une option",
  weekly: "Hebdomadaire",
  monthly: "Mensuel",
  male: "Masculin",
  female: "Féminin",
  "other-gender": "Non binaire",
  "yes-to-promo-email": "Oui",
  "no-to-promo-email": "Non",
  english: "Anglais",
  french: "Français",
  spanish: "Espagnol",
  portuguese: "Portugais",
  "simplified-chinese": "Chinois (Simplifié)",
  "traditional-chinese": "Chinois (Traditionnel)",
  "default-submit-button": "Soumettre",
  "default-submit-button-success": "Soumis",
  "default-submit-button-processing": "En cours...",
  "optional-label": "Optionnel",
  "enter-in-input-mask-pattern-format": "Veuillez saisir en utilisant le format [mask-pattern]",
  "generic-fallback-api-error":
    "Une erreur s'est produite lors de la soumission, veuillez réessayer",
  "invalid-address-error": "Veuillez saisir une adressse correcte",
  "accept-terms-text": "J'ai lu, j'ai compris et j'accepte les [terms-and-conditions].",
  "accept-terms-link-text": "Conditions d'utilisation",
  "add-new-gift-card-invalid-card-error": "Le numéro de carte est incorrect, veuillez réessayer.",
  "special-delivery-instructions-field-label": "Instructions spéciales de livraison",
  "zip-code": "Code postal",
  "unit-number": "Numéro d'appartement",
  "invalid-phone-number": "Numéro de téléphone incorrect",
  "incorrect-username-password": "Le nom d'utilisateur/mot de passe est incorrect.",
  "save-credit-card-login-error":
    "Veuillez vous identifier ou créer un compte pour sauvegarder la carte de crédit et accélérer votre paiement la prochaine fois.",
  "invalid-delivery-address-error": "Veuillez saisir une adresse complète et correcte",
  "street-address": "Rue",
  "apt-suit-unit": "Apt",
  "primary-address": "Adresse principale",
  section: "Section",
  row: "Rangée",
  seat: "Siège",
  "do-not-include-country-code": "Veuillez ne pas inclure le code pays",
  "eGiftCard-amount-incorrect": "Le montant fourni est incorrect",
  "eGiftCard-amount-not-numeric": "Le montant doit être numérique",
  "eGiftCard-date-time-incorrect": "La date ou l'heure est incorrecte",
  "eGiftCard-value-negative": "La valeur doit être supérieure à 0",
  "invalid-amount": "Montant invalide",
};
