export const generalLabels = {
  welcome: "Bienvenido",
  "loading-your-setup": "Cargando su configuración",
  "good-morning": "Buenos días",
  "good-afternoon": "Buenas tardes",
  "good-evening": "Buenas noches",
  account: "Cuenta",
  "scan-in-store": "Escanear",
  "my-account": "Mi Cuenta",
  rewards: "Lealtad",
  "gift-cards": "Tarjeta de Regalo",
  order: "Pedido",
  "order-now": "Ordene Ahora",
  "find-store": "Buscar una sucursal",
  "terms-and-conditions": "Términos y Condiciones",
  maintenance_message: "Este sitio esta en mantenimiento, Volveremos en breve …",
  "cookie-message":
    "Utilizamos cookies para mejorar su experiencia de compra haciendo nuestro sitio mas rápido, también para analizar el trafico a nuestro sitio. [link] para conocer mas. Si continua navegando en nuestro sitio asumimos que usted acepta su uso",
  "cookie-message-link-text": "Vea nuestra política",
  "remove-all-cart-items-confirmation-message":
    "Esta seguro que desea eliminar todos los artículos de su carro?",
  "dialog-modal-confirm": "Confirmar",
  "dialog-modal-cancel": "Cancelar",
  "dialog-modal-ok": "Ok",
  "expires-on": "Expira en",
  remove: "Eliminar",
  "credit-card": "Tarjeta de Crédito",
  "gift-card": "Tarjeta de Regalo",
  optional: "Opcional",
  subtotal: "Subtotal",
  total: "Total",
  barcode: "Código de Barras",
  "something-went-wrong-error": "Algo salió mal. Por favor intente de nuevo",
  "login-expired-modal-message":
    "Su sesión ha expirado. Estamos redireccionándolo a la pagina de inicio",
  "add-to-home-screen-prompt": "Agregar a Pagina Inicial",
  "logging-you-out": "Saliendo…",
  "disabled-geolocation-alert":
    "Para buscar tiendas cercanas, active los servicios de geolocalizacion en su dispositivo y permita a la app utilizar su ubicacion",
  "renew-expired-session-confirmation": "Su sesión ha expirado, desea renovarla?",
  "expired-session-logout-countdown": "Se sesión se desconectara en [número] segundos",
  "your-last-visit": "Su ultima visita",
  "new-version-message": "Hay una nueva actualización disponible. Por favor confirme para cargarla",
  monday: "Lunes",
  tuesday: "Martes",
  wednesday: "Miércoles",
  thursday: "Jueves",
  friday: "Viernes",
  saturday: "Sábado",
  sunday: "Domingo",
  "default-loyalty-card": "Tarjeta Predeterminada",
  "app-version": "Version [number]",
  "error-code": "Codigo de Error",
  "main-website": "Pagina Web Principal",
  applied: "Aplicado",
  "go-back": "Regresar",
  "close-dialog-modal": "Cerrar dialogo modal",
  "close-modal": "Cerrar modal",
  close: "cerrar",
  "close-disclaimer": "Cerrar descargo de responsabilidad",
  "close-install-prompt": "Cerrar instalacion de aplicacion",
  hide: "Ocultar",
  show: "Mostrar",
  open: "Abrir",
  "language-dropdown": "menú desplegable de idioma",
  "show-info-active-store": "Mostrar info de la tienda actualmente activa",
  "show-info-all-stores": "Mostrar info de todas las tiendas en el rango",
  "find-location-google-maps": "Buscar mi ubicacion actual en Google Maps",
  "search-address": "Buscar direccion",
  "close-store-info": "Cerrar informacion de la tienda",
  options: "opciones",
  "change-barcode-type-to": "Cambiar el tipo de codigo de barras a [barcode-type]",
  "show-app-header-order-summary": "Mostrar la hora del pedido y detalles de la ubicacion",
  "hide-app-header-order-summary": "Ocultar la hora del pedido y detalles de la ubicacion",
  "language-dropdown-display-name": "Idioma",
  done: "Pronto",
  january: "Enero",
  february: "Febrero",
  march: "Marzo",
  april: "Abril",
  may: "Mayo",
  june: "Junio",
  july: "Julio",
  august: "Agosto",
  september: "Septiembre",
  october: "Octubre",
  november: "Noviembre",
  december: "Diciembre",
  "client-gift-card-website-label": "Tarjeta de Regalo",
  skip: "Omitir",
  "xp-subscribe-to-notifications-header": "¡Nunca te lo pierdas!",
  "xp-subscribe-to-notifications-message":
    "Permita que [merchant-name] le envíe ofertas y descuentos exclusivos.",
  select: "Seleccionar",
  "for-text": "para",
  "go-to-dashboard": "Ir al panel de control",
  modify: "Modificar",
  checking: "De cheques",
  home: "Hogar",
  "hide-menu": "Ocultar el menú",
  resources: "Recursos",
};
