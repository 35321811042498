import React, { useContext, useEffect, useState } from "react";
/** Contexts */
import AppLabelsContext from "../../../App/AppLabelsContext";
import AppLanguageContext from "../../../App/AppLanguageContext";
import AppSettingsContext from "../../../App/AppSettingsContext";
import BrandingContext from "../../../App/BrandingContext";
import CartContext from "../../Cart/CartContext";

/** Helpers */
import { jsonCopy, toDollars } from "../../../_common/helpers";
import { getContrastColor } from "../../../_common/colorHelpers";
import { getItemQuantityFromCart } from "../../../_common/CartHelpers";
import { isOutOfStock } from "../helpers/orderingHelpers";

/** UI component */
import MenuItemQuickAddButton from "./QuickOrderButton/MenuItemQuickAddButton";

/** CSS */
import "./MenuCategoryCard.css";

export const MenuCategoryCard = ({ handleClick, itemData, index, isFeaturedItemCard }) => {
  const {
    id,
    name,
    proxyImageUrl,
    imageUrl,
    price,
    calorie_count,
    type,
    item_count,
    onlineQuantityLimit,
  } = itemData;

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const appLabels = useContext(AppLabelsContext);

  const brandingContext = useContext(BrandingContext);
  const imagePlaceholder = brandingContext["no-image-available-placeholder"];
  const outOfStockImage = brandingContext["item-out-of-stock-image"];
  const itemNameBackgroundColor = brandingContext["menu-card-item-name-background"];
  const cartData = useContext(CartContext);
  const cartItems = cartData.value || [];

  const isFeaturedItem = name.toLowerCase() === "featured";
  const displayName = isFeaturedItem ? appLabels["order"]["featured"] : name;

  const cardButtonLabel = appLabels["order"]["show-category-subcategories"].replace(
    "[category-name]",
    name.toLowerCase()
  );

  const displayPrice = toDollars(
    appSettings["currency-symbol"],
    appSettings["currency-symbol-side"],
    price,
    appLanguage
  );

  const textColor = itemNameBackgroundColor ? getContrastColor(itemNameBackgroundColor) : "#2a2a2a";
  const hasNoAddonModifier =
    type === "productItem" &&
    Object.keys(itemData.modifierGroups).length === 0 &&
    Object.keys(itemData.addonGroups).length === 0 &&
    (Number(item_count > 0) || item_count === "None");

  const [isQuickAddButtonEnabled, setIsQuickAddButtonEnabled] = useState(false);
  const [preSelectedItem, setPreSelectedItem] = useState(null);

  useEffect(() => {
    if (itemData && type === "productItem" && (Number(item_count > 0) || item_count === "None")) {
      let tempPreSelectedItem = jsonCopy(itemData);
      delete tempPreSelectedItem.entryKey;
      delete tempPreSelectedItem.onlineQuantityLimit;
      /** there is no addon or modifier groups, show the quick add button */
      if (
        Object.keys(tempPreSelectedItem.modifierGroups).length === 0 &&
        Object.keys(tempPreSelectedItem.addonGroups).length === 0
      ) {
        setIsQuickAddButtonEnabled(true);
      } else if (
        Object.keys(tempPreSelectedItem.modifierGroups).length > 0 ||
        Object.keys(tempPreSelectedItem.addonGroups).length > 0
      ) {
        /** if there is addon or modifier group check and see if there is default selection */
        const addonGroups = tempPreSelectedItem.addonGroups;
        const modifierGroups = tempPreSelectedItem.modifierGroups;

        let hasDefaultSelectionForAddons = false;
        let hasDefaultSelectionForNestedModifiers = false;
        let hasDefaultSelectionForModifiers = false;

        let numberOfDefaultRequired = 0;
        // Loop the addon groups
        Object.keys(addonGroups).forEach((addonGroupKey) => {
          const addonGroup = addonGroups[addonGroupKey];
          const isSingleAddon = !addonGroup.id;
          addonGroup.type = "addonGroup";
          const addonGroupMinQuantity = Number(addonGroup.minQuantity);
          const addons = addonGroup.items;
          if (!isSingleAddon) {
            if (addonGroupMinQuantity > 0) {
              numberOfDefaultRequired += addonGroupMinQuantity;
            }

            Object.keys(addons).forEach((addonKey) => {
              const addon = addons[addonKey];
              if (addon.isDefault === "True" && !isOutOfStock(addon)) {
                tempPreSelectedItem.addonGroups[addonGroupKey].items[addonKey].isSelected = true;
                tempPreSelectedItem.addonGroups[addonGroupKey].items[addonKey].quantity = 1;
                if (addonGroupMinQuantity > 0) {
                  numberOfDefaultRequired--;
                }
                //check if addon has a nested modifier group
                if (Object.keys(addon.modifierGroups).length > 0) {
                  const nestedModifierGroups = addon.modifierGroups;
                  Object.keys(nestedModifierGroups).forEach((nestedModifierGroupKey) => {
                    const nestedModifierGroup = nestedModifierGroups[nestedModifierGroupKey];
                    const nestedModifierGroupMinQuantity = Number(nestedModifierGroup.minQuantity);

                    if (nestedModifierGroupMinQuantity > 0) {
                      numberOfDefaultRequired += nestedModifierGroupMinQuantity;
                    }
                    const nestedModifiers = nestedModifierGroup.items;
                    Object.keys(nestedModifiers).forEach((nestedModifierKey) => {
                      const nestedModifier = nestedModifiers[nestedModifierKey];
                      if (nestedModifier.isDefault === "True") {
                        hasDefaultSelectionForNestedModifiers = true;
                        if (nestedModifierGroupMinQuantity > 0) {
                          numberOfDefaultRequired--;
                        }
                        tempPreSelectedItem.addonGroups[addonGroupKey].items[
                          addonKey
                        ].modifierGroups[nestedModifierGroupKey].items[
                          nestedModifierKey
                        ].isSelected = true;
                      } else {
                        delete tempPreSelectedItem.addonGroups[addonGroupKey].items[addonKey]
                          .modifierGroups[nestedModifierGroupKey].items[nestedModifierKey]
                          .isSelected;
                      }
                    });
                  });
                }
              } else {
                delete tempPreSelectedItem.addonGroups[addonGroupKey].items[addonKey].isSelected;
                delete tempPreSelectedItem.addonGroups[addonGroupKey].items[addonKey].quantity;
              }
            });
          } else if (isSingleAddon) {
            addonGroup.isStandalone = true;
            Object.keys(addons).forEach((addonKey) => {
              const addon = addons[addonKey];
              addonGroup.name = addon.name;
              addonGroup.id = addon.id;
              addon.isStandalone = true;
              if (addon.isDefault === "True" && !isOutOfStock(addon)) {
                tempPreSelectedItem.addonGroups[addonGroupKey].items[addonKey].isSelected = true;
                tempPreSelectedItem.addonGroups[addonGroupKey].items[addonKey].quantity = 1;
              }
            });
          }
          /** numberOfDefaultRequired will reach a negative value if there are more than one default item in a group and the minQuantity is 1 */
          if (numberOfDefaultRequired < 0) {
            numberOfDefaultRequired = 0;
          }
        });

        // numberOfDefaultRequired will reach a negative value if there are multiple default selectons in a group which has a min quantity of 1
        if (numberOfDefaultRequired < 0) {
          numberOfDefaultRequired = 0;
        }

        // Loop the modifier groups
        Object.keys(modifierGroups).forEach((modifierGroupKey) => {
          const modifierGroup = modifierGroups[modifierGroupKey];
          modifierGroup.type = "modifierGroup";
          const modifiers = modifierGroup.items;
          const modifierGroupMinQuantity = Number(modifierGroup.minQuantity);

          if (modifierGroupMinQuantity > 0) {
            numberOfDefaultRequired += modifierGroupMinQuantity;
          }
          Object.keys(modifiers).forEach((modifierKey) => {
            const modifier = modifiers[modifierKey];
            if (modifier.isDefault === "True") {
              if (modifierGroupMinQuantity > 0) {
                numberOfDefaultRequired--;
              }
              tempPreSelectedItem.modifierGroups[modifierGroupKey].items[
                modifierKey
              ].isSelected = true;
            } else {
              delete tempPreSelectedItem.modifierGroups[modifierGroupKey].items[modifierKey]
                .isSelected;
            }
          });

          /** numberOfDefaultRequired will reach a negative value if there are more than one default item in a group and the minQuantity is 1 */
          if (numberOfDefaultRequired < 0) {
            numberOfDefaultRequired = 0;
          }
        });

        if (numberOfDefaultRequired === 0) {
          hasDefaultSelectionForAddons = true;
          hasDefaultSelectionForNestedModifiers = true;
          hasDefaultSelectionForModifiers = true;
        } else {
          hasDefaultSelectionForAddons = false;
          hasDefaultSelectionForNestedModifiers = false;
          hasDefaultSelectionForModifiers = false;
        }

        if (
          hasDefaultSelectionForModifiers &&
          hasDefaultSelectionForAddons &&
          hasDefaultSelectionForNestedModifiers
        ) {
          setIsQuickAddButtonEnabled(true);
          setPreSelectedItem(tempPreSelectedItem);
        } else if (cartItems.filter((item) => item.id === id).length > 0) {
          setIsQuickAddButtonEnabled(true);
        } else {
          setIsQuickAddButtonEnabled(false);
          setPreSelectedItem(null);
        }
      }
    } else if (
      itemData.type === "comboItem" &&
      cartItems.filter((item) => item.id === id).length > 0
    ) {
      setIsQuickAddButtonEnabled(true);
    } else {
      setIsQuickAddButtonEnabled(false);
      setPreSelectedItem(null);
    }
  }, [cartData]);

  return (
    <>
      {isFeaturedItemCard ? (
        <div
          className={`${
            isFeaturedItemCard ? " menu__category-card-featured" : " menu__category-card"
          } ${isQuickAddButtonEnabled ? " withQuickAddButton" : ""}`}
          style={{
            backgroundColor: itemNameBackgroundColor || "#fff",
          }}
        >
          <div className="menu-category-card__content-wrapper">
            <div className="menu-category-card__image-wrapper">
              <img
                src={imageUrl || proxyImageUrl || imagePlaceholder}
                alt={displayName}
                className="menu-category-card__image"
                loading={index > 4 ? "lazy" : "auto"}
              />
              {Number(item_count) <= 0 && (
                <img
                  src={outOfStockImage}
                  alt={displayName}
                  className="menu-category-card__image-overlay"
                />
              )}
            </div>
            <div
              className="menu-category-card___text-wrapper"
              style={{
                backgroundColor: itemNameBackgroundColor || "#fff",
                borderBottomLeftRadius: isQuickAddButtonEnabled ? "0px" : "",
                borderBottomRightRadius: isQuickAddButtonEnabled ? "0px" : "",
              }}
            >
              <h2
                className="menu-category-card__name"
                style={{
                  color: textColor,
                }}
              >
                {displayName}
              </h2>
              {price && (
                <>
                  {price > 0 ? (
                    <div
                      className="menu-category-card__price"
                      style={{
                        color: textColor,
                      }}
                    >
                      {displayPrice}
                    </div>
                  ) : (
                    <div
                      className="menu-category-card__price"
                      style={{
                        color: textColor,
                      }}
                    >
                      {appLabels["order"]["price-based-on-selection"]}
                    </div>
                  )}
                </>
              )}
              {calorie_count && (
                <div
                  className="menu-category-card__calories"
                  style={{
                    color: textColor,
                  }}
                >{`${calorie_count} ${appLabels["order"]["calories"]}`}</div>
              )}

              {onlineQuantityLimit && (
                <div
                  className="menu-category-card__price menu-category-card__limit"
                  style={{
                    color: textColor,
                  }}
                >
                  {getItemQuantityFromCart(cartItems, itemData.id) >= onlineQuantityLimit ? (
                    <>{appLabels["order"]["limit-reached"]}</>
                  ) : (
                    <>
                      {appLabels["order"]["limit-per-order"].replace(
                        "[qty-limit]",
                        onlineQuantityLimit
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
            <button
              onClick={() => handleClick(id, type, itemData)}
              className="menu-category-card__button"
              aria-label={cardButtonLabel}
              type="button"
              style={type === "productItem" || type === "comboItem" ? { height: "87%" } : {}}
            />

            {isQuickAddButtonEnabled && (
              <MenuItemQuickAddButton
                itemData={itemData}
                requiresCustomization={
                  itemData.type === "comboItem" ||
                  Object.keys(itemData.modifierGroups).length > 0 ||
                  Object.keys(itemData.addonGroups).length > 0
                }
                onlineQuantityLimit={Number(onlineQuantityLimit)}
                preSelectedItem={preSelectedItem}
                hasNoAddonModifier={hasNoAddonModifier}
              />
            )}
          </div>
        </div>
      ) : (
        <li
          className={`${
            isFeaturedItemCard ? " menu__category-card-featured" : " menu__category-card"
          } ${isQuickAddButtonEnabled ? " withQuickAddButton" : ""}`}
          style={{
            backgroundColor: itemNameBackgroundColor || "#fff",
          }}
        >
          <div className="menu-category-card__content-wrapper">
            <div className="menu-category-card__image-wrapper">
              <img
                src={imageUrl || proxyImageUrl || imagePlaceholder}
                alt={displayName}
                className="menu-category-card__image"
                loading={index > 4 ? "lazy" : "auto"}
              />
              {Number(item_count) <= 0 && (
                <img
                  src={outOfStockImage}
                  alt={displayName}
                  className="menu-category-card__image-overlay"
                />
              )}
            </div>
            <div
              className="menu-category-card___text-wrapper"
              style={{
                backgroundColor: itemNameBackgroundColor || "#fff",
                borderBottomLeftRadius: isQuickAddButtonEnabled ? "0px" : "",
                borderBottomRightRadius: isQuickAddButtonEnabled ? "0px" : "",
              }}
            >
              <h2
                className="menu-category-card__name"
                style={{
                  color: textColor,
                }}
              >
                {displayName}
              </h2>
              {price && (
                <>
                  {price > 0 ? (
                    <div
                      className="menu-category-card__price"
                      style={{
                        color: textColor,
                      }}
                    >
                      {displayPrice}
                    </div>
                  ) : (
                    <div
                      className="menu-category-card__price"
                      style={{
                        color: textColor,
                      }}
                    >
                      {appLabels["order"]["price-based-on-selection"]}
                    </div>
                  )}
                </>
              )}
              {calorie_count && (
                <div
                  className="menu-category-card__calories"
                  style={{
                    color: textColor,
                  }}
                >{`${calorie_count} ${appLabels["order"]["calories"]}`}</div>
              )}

              {onlineQuantityLimit && (
                <div
                  className="menu-category-card__price menu-category-card__limit"
                  style={{
                    color: textColor,
                  }}
                >
                  {getItemQuantityFromCart(cartItems, itemData.id) >= onlineQuantityLimit ? (
                    <>{appLabels["order"]["limit-reached"]}</>
                  ) : (
                    <>
                      {appLabels["order"]["limit-per-order"].replace(
                        "[qty-limit]",
                        onlineQuantityLimit
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
            <button
              onClick={() => handleClick(id, type, itemData)}
              className="menu-category-card__button"
              aria-label={cardButtonLabel}
              type="button"
              style={type === "productItem" || type === "comboItem" ? { height: "87%" } : {}}
            />

            {isQuickAddButtonEnabled && (
              <MenuItemQuickAddButton
                itemData={itemData}
                requiresCustomization={
                  itemData.type === "comboItem" ||
                  Object.keys(itemData.modifierGroups).length > 0 ||
                  Object.keys(itemData.addonGroups).length > 0
                }
                onlineQuantityLimit={Number(onlineQuantityLimit)}
                preSelectedItem={preSelectedItem}
                hasNoAddonModifier={hasNoAddonModifier}
              />
            )}
          </div>
        </li>
      )}
    </>
  );
};
